import React from 'react'

import type {
  ContentfulCustomModuleParamsJsonNodeInitiatives,
  FullAssetFragment,
  Maybe,
} from '../../../../../graphql-types'

import {
  StatCard,
  StatsContainer,
  InitiativesContainer,
  Img,
  Container,
  InitiativeCard,
  TopParagraph,
  BottomParagraph,
  Separator,
} from './styled'

type Initiative = Pick<
  ContentfulCustomModuleParamsJsonNodeInitiatives,
  'emoji' | 'text'
>

type Props = {
  paragraphTop?: Maybe<string>
  paragraphBottom?: Maybe<string>
  initiatives?: Maybe<Maybe<Initiative>[]>
  imageList?: Maybe<Maybe<FullAssetFragment>[]>
}

const DiversityAndEquity = ({
  paragraphTop,
  paragraphBottom,
  imageList,
  initiatives,
}: Props) => {
  const image0 = imageList ? imageList[0] : null
  const image1 = imageList ? imageList[1] : null
  const image2 = imageList ? imageList[2] : null
  const image3 = imageList ? imageList[3] : null

  return (
    <Container>
      <TopParagraph>{paragraphTop}</TopParagraph>
      <StatsContainer>
        <StatCard>
          <Img src={image0?.file?.url || ''} alt={image0?.description || ''} />
        </StatCard>
        <StatCard>
          <Img src={image1?.file?.url || ''} alt={image1?.description || ''} />
        </StatCard>
        <StatCard>
          <Img src={image2?.file?.url || ''} alt={image2?.description || ''} />
          <Separator></Separator>
          <Img src={image3?.file?.url || ''} alt={image3?.description || ''} />
        </StatCard>
      </StatsContainer>
      <BottomParagraph>{paragraphBottom}</BottomParagraph>
      <InitiativesContainer>
        {initiatives?.map(
          (el, index) =>
            el && (
              <InitiativeCard key={index}>
                <span>{el.emoji}</span>
                <p>{el.text}</p>
              </InitiativeCard>
            ),
        )}
      </InitiativesContainer>
    </Container>
  )
}

export default DiversityAndEquity
