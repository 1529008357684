import { useStaticQuery, graphql } from 'gatsby'
import React, { useMemo } from 'react'

import type { DoublePricingsListQuery } from '../../../../graphql-types'
import isBrowser from '../../../utils/isBrowser'
import Container from '../Container'
import DoublePricingsList from '../DoublePricingsList'

const PRICING_STATE = 'pricingState'

export const query = graphql`
  query DoublePricingsList {
    double {
      availablePricingsList: pricingsList(pricingState: AVAILABLE) {
        ...Double_PricingsList
      }
      publicPricingsList: pricingsList(pricingState: PUBLIC) {
        ...Double_PricingsList
      }
    }
  }
`

type Props = {
  ctaUrl?: string
}

const PricingsList = ({ ctaUrl }: Props) => {
  const data = useStaticQuery<DoublePricingsListQuery>(query)

  const displayAvailable = useMemo(() => {
    if (!isBrowser() || !window.location.search) {
      return false
    }

    const url = new URL(window.location.href)
    const pricingState = url.searchParams.get(PRICING_STATE)

    return pricingState === 'available'
  }, [])

  return (
    <Container>
      <DoublePricingsList
        ctaUrl={ctaUrl}
        items={
          (displayAvailable
            ? data.double.availablePricingsList
            : data.double.publicPricingsList
          ).items
        }
      />
    </Container>
  )
}

export default PricingsList
