import React from 'react'
import styled, { css } from 'styled-components'

import type { PeopleFragment, PersonFragment } from '../../../graphql-types'
import { mobile, disc } from '../mixins'
import { Color } from '../theme'
import { BodySemibold, CaptionRegular, Heading3 } from '../typography'

import Img from './Img'

const PersonOuter = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: 24px;

  & + & {
    margin-top: 32px;
  }
`
const PersonInner = styled.div`
  padding-left: 16px;
  display: flex;
  flex-direction: column;
`
const Description = styled(CaptionRegular)`
  color: ${Color.GRAY_6};
`

const Avatar = styled(Img)`
  ${disc(64)};
  background-color: ${Color.GRAY_2};

  ${mobile(disc(64))}
`

type PersonProps = PersonFragment

const Person = ({ name, avatar, description }: PersonProps) => (
  <PersonOuter>
    <Avatar
      asset={avatar}
      // maxWidth={80}
    />
    <PersonInner>
      <BodySemibold>{name}</BodySemibold>
      <Description>{description}</Description>
    </PersonInner>
  </PersonOuter>
)

const PeopleOuter = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0 24px 24px;
  margin: 0 auto;
  max-width: 536px;

  ${mobile(css`
    padding-left: 0;
    padding-right: 0;
  `)}
`

const Title = styled(Heading3)`
  text-align: center;
  margin-bottom: 8px;
`

type Props = PeopleFragment

const People = ({ items, title }: Props) => (
  <PeopleOuter>
    {!!title && <Title>{title}</Title>}
    {items?.map(
      (person, index) => !!person && <Person key={index} {...person} />,
    )}
  </PeopleOuter>
)

export default People
