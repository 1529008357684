import type { ComponentProps } from 'react'
import React from 'react'

import type {
  CardItemFragment,
  CardsListFragment,
} from '../../../../graphql-types'

import { Emoji, ItemOuter, Title, Text, Outer, EmojiImg } from './styled'

type ItemProps = CardItemFragment & ComponentProps<typeof ItemOuter>

const CardItem = ({ title, text, emoji, image }: ItemProps) => (
  <ItemOuter>
    {image && <EmojiImg src={image.file?.url || ''} />}
    {emoji && <Emoji>{emoji}</Emoji>}
    <Title>{title}</Title>
    <Text>{text?.text}</Text>
  </ItemOuter>
)

type Props = CardsListFragment

const CardsList = ({ items }: Props) => (
  <Outer>
    {items?.map((item, index) => !!item && <CardItem key={index} {...item} />)}
  </Outer>
)

export default CardsList
