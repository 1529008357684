import { transparentize } from 'polished'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

import type {
  Double_PricingFragment,
  Double_PricingsListFragment,
} from '../../../../graphql-types'
import { maxScreenWidth, mobile } from '../../mixins'
import { Color } from '../../theme'
import {
  Heading3,
  CalloutRegular,
  heading3Style,
  Heading2,
  calloutSemiboldStyle,
  CalloutSemibold,
} from '../../typography'
import { List, Bullet } from '../TasksList'

import HalfPerson from './half-person.inline.svg'
import HalfTeam from './half-team.inline.svg'
import Person from './person.inline.svg'

const COLUMN_MAX_WIDTH = 760
const COLUMN_GAP = 24

const COLORS = [
  [Color.BLUE_5, Color.BLUE_4],
  [Color.PURPLE_5, Color.PURPLE_4],
  [Color.VIOLET_5, Color.VIOLET_4],
  [Color.FLAMINGO_5, Color.FLAMINGO_4],
]

interface ItemOuterProps {
  $vividColor: string
  $fadedColor: string
}

const Label = styled(Heading3)``

const Button = styled.a`
  ${calloutSemiboldStyle}
  border-radius: 10px;
  height: 44px;
  padding: 9px 16px;
  margin: 16px 0;
  border: 1.5px solid transparent;
  width: 100%;
  text-align: center;
  transition: color 150ms, background 150ms;

  ${mobile(css`
    margin: 32px 0 16px;
  `)}
`

const getItemFlexBasisPercentage = (nbItemsPerRow: number) => {
  const totalGap = (nbItemsPerRow - 1) * COLUMN_GAP
  const totalWidth = COLUMN_MAX_WIDTH - 2 * 32 // 32px padding on each side, see src/components/modules/Container.ts
  const flexBasis = (totalWidth - totalGap) / nbItemsPerRow
  return (flexBasis / totalWidth) * 100
}
const ItemOuter = styled.div<ItemOuterProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 24px;
  min-height: 360px;
  border-radius: 16px;
  border: 1px solid ${Color.GRAY_3};
  max-width: 360px;

  flex: 1 1 ${getItemFlexBasisPercentage(4)}%;
  /* Empiric value for the price to not be cropped and buttons to stay in one line*/
  ${maxScreenWidth(1000)(css`
    flex: 1 1 ${getItemFlexBasisPercentage(2)}%;
  `)}

  overflow: hidden;

  :before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      ${({ $vividColor }) => transparentize(0.92)($vividColor)} 0%,
      ${({ $vividColor }) => transparentize(1)($vividColor)} 500px
    );
    opacity: 0;
    transition: opacity 150ms;
    border-radius: 16px;
  }
  :hover:before {
    opacity: 1;
  }

  ${Label} {
    color: ${({ $vividColor }) => $vividColor};
  }

  ${Button} {
    border-color: ${({ $vividColor }) => $vividColor};
    color: ${({ $vividColor }) => $vividColor};
    :hover {
      color: white;
      background: ${({ $vividColor }) => $vividColor};
    }
  }

  ${maxScreenWidth(COLUMN_MAX_WIDTH)(css`
    width: 100%;
  `)}
`

const Price = styled(Heading2)`
  margin: 16px 0 16px !important;
  max-width: 100%;

  :after {
    content: '/mo';
    ${heading3Style};
  }
`
const Hours = styled.span`
  ${calloutSemiboldStyle}
`

const Description = styled(CalloutRegular)<{ $marginBottom: number }>`
  ${({ $marginBottom }) => css`
    margin-bottom: ${$marginBottom}px;
  `}
  padding-left: 26px;
`

const Inheritance = styled(CalloutSemibold)`
  color: ${Color.GRAY_7};
  margin-bottom: 16px;
`

const SellingPoints = styled(List)`
  border-top: 1px solid ${Color.GRAY_3};
  margin-top: 16px;
  padding-top: 30px;
  width: 100%;
`

const SellingPoint = styled(CalloutRegular)`
  color: ${Color.GRAY_7};
`

const TimePie = styled.div<{ $ratio: number; $color: string }>`
  position: absolute;
  top: 4px;
  left: 0px;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  border: 2px solid ${({ $color }) => $color};

  :before {
    content: '';
    position: absolute;
    border-radius: 50%;
    inset: 0;
    background: conic-gradient(
      ${({ $color, $ratio }) => `${$color} ${$ratio * 100}%`},
      #0000 0
    );
  }
`

const DescriptionIconOuter = styled.div<{ $color: string }>`
  color: ${({ $color }) => $color};

  position: absolute;
  top: 3px;
  left: -3px;
  width: 20px;
  height: 20px;
`

export type ItemProps = Double_PricingFragment & {
  colors: string[]
  timeRatio: number
  ctaUrl?: string
}

const PricingItem = ({
  id,
  label,
  description,
  hourlyPrice,
  monthlyPrice,
  sellingPoints,
  sellingPointsNotice,
  includedHours,
  colors: [vividColor, fadedColor],
  timeRatio,
  ctaUrl = '/sign-up/why-double',
}: ItemProps): JSX.Element => (
  <ItemOuter
    className={'pricing'}
    id={id || label?.toLocaleLowerCase()}
    $vividColor={vividColor}
    $fadedColor={fadedColor}
  >
    <Label noColor noResponsive>
      {label}
    </Label>
    <Price noResponsive>{`$${monthlyPrice}`}</Price>
    {description && (
      <Description noResponsive $marginBottom={16}>
        <DescriptionIconOuter $color={fadedColor}>
          {timeRatio < 0.3 ? (
            <Person />
          ) : timeRatio < 0.9 ? (
            <HalfPerson />
          ) : (
            <HalfTeam />
          )}
        </DescriptionIconOuter>
        {description}
      </Description>
    )}
    <Description noResponsive $marginBottom={32}>
      <TimePie $color={fadedColor} $ratio={timeRatio} />
      {'Includes '}
      <Hours noResponsive>{`${includedHours} hours`}</Hours>
      {' per month'}
      <br />
      {`Every additional hour is $${hourlyPrice}`}
    </Description>
    <Button href={ctaUrl} noResponsive noColor>
      {`Select ${label}`}
    </Button>
    {!!(sellingPoints?.length || sellingPointsNotice) && (
      <SellingPoints>
        {!!sellingPointsNotice && (
          <Inheritance noResponsive>{sellingPointsNotice}</Inheritance>
        )}
        {sellingPoints?.map(
          (text, index) =>
            !!text && (
              <Bullet key={index} noCheck>
                <SellingPoint noResponsive>{text}</SellingPoint>
              </Bullet>
            ),
        )}
      </SellingPoints>
    )}
  </ItemOuter>
)

const Outer = styled.div`
  display: flex;
  justify-content: center;
  column-gap: ${COLUMN_GAP}px;
  row-gap: 32px;
  flex-wrap: wrap;

  ${maxScreenWidth(COLUMN_MAX_WIDTH)(css`
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  `)}
`

type PrincingsListProps = Pick<Double_PricingsListFragment, 'items'> & {
  ctaUrl?: string
}

const PricingsList = ({ items, ctaUrl }: PrincingsListProps): JSX.Element => {
  const maxHours = useMemo(
    () =>
      items.reduce<number>(
        (maxHours, pricing) => Math.max(pricing.includedHours, maxHours),
        0,
      ),
    [items],
  )

  const orderedItems = useMemo(
    () =>
      items.sort((a, b) => {
        if (a.isSharable && !b.isSharable) return 1
        if (!a.isSharable && b.isSharable) return -1

        return (a.includedHours || 0) - (b.includedHours || 0)
      }),
    [items],
  )

  return (
    <Outer id={'pricings'}>
      {orderedItems.map((item, index) => (
        <PricingItem
          key={item.id as string}
          colors={COLORS[index % COLORS.length]}
          timeRatio={Math.min(item.includedHours / maxHours, 0.95)}
          ctaUrl={ctaUrl}
          {...item}
        />
      ))}
    </Outer>
  )
}

export default PricingsList
