import type { ReactNode } from 'react'
import React from 'react'

import { RadioInput, RadioLabel } from './styled'

interface RadioChoiceProps {
  name: string
  value?: string
  options: { label: ReactNode; value: string }[]
  onChange: (value: string) => void
}

const RadioChoice = ({ name, value, options, onChange }: RadioChoiceProps) => (
  <div>
    {options.map((option, index) => (
      <RadioInput
        key={`${option.value} ${index}`}
        name={name}
        value={option.value}
        checked={value === option.value}
        onChangeValue={onChange}
      >
        <RadioLabel>{option.label}</RadioLabel>
      </RadioInput>
    ))}
  </div>
)

export default RadioChoice
