import type { ReactNode, ComponentProps } from 'react'
import React from 'react'
import styled, { css } from 'styled-components'

import type { FullAssetFragment, LinkFragment } from '../../graphql-types'

import { tablet } from './mixins'
import Img from './modules/Img'
import _Link from './modules/Link'
import {
  heading2Style,
  BodyRegular,
  CalloutSemibold,
  BodySemibold,
} from './typography'
import { renderTextWithLineBreaks } from './utils'

const Outer = styled.div<{ reversed?: boolean }>`
  display: flex;
  align-items: center;
  ${({ reversed }) =>
    !!reversed &&
    css`
      flex-direction: row-reverse;
    `}

  ${tablet(css`
    flex-direction: column;
  `)}
`

const Spacer = styled.div`
  flex: 0 0 ${(80 / 1200) * 100}%;

  ${tablet(css`
    display: none;
  `)}
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const ImgOuter = styled.div`
  flex: 0 0 ${(640 / 1200) * 100}%;
  padding: 30px 0;
  text-align: center;

  ${tablet(css`
    flex: 0 0 auto;
  `)}
`

const Image = styled(Img)`
  max-width: 100%;
`

const titleStyle = css`
  ${heading2Style}
  & + * {
    margin-top: 16px;
  }
`

const TitleH1 = styled.h1`
  ${titleStyle}
`

const TitleH2 = styled.h2`
  ${titleStyle}
`

const Link = styled(_Link)`
  margin-top: 32px;
`

const Category = styled<{ color?: string | null | undefined }>(BodySemibold)`
  ${({ color }) =>
    !!color &&
    css`
      color: ${color?.startsWith('#') ? '' : '#'}${color};
    `}
`

type Props = {
  title?: string | undefined | null
  text?: string | undefined | null
  image?: FullAssetFragment | undefined | null
  imageHeight?: number | undefined | null
  imageCaption?: React.ReactNode
  imageStyle?: React.CSSProperties
  link?: LinkFragment | undefined | null
  children?: ReactNode | undefined | null
  category?:
    | undefined
    | null
    | {
        name?: string | undefined | null
        color?: string | undefined | null
      }
  useH1?: boolean
} & ComponentProps<typeof Outer>

const TextImage = ({
  title,
  text,
  link,
  image,
  children,
  category,
  useH1,
  imageHeight,
  imageCaption,
  imageStyle,
  ...outerProps
}: Props) => (
  <Outer {...outerProps}>
    <Inner>
      {!!category && (
        <Category color={category.color}>{category.name}</Category>
      )}
      {useH1 ? (
        <TitleH1 children={title} />
      ) : (
        <TitleH2 noResponsive children={title} />
      )}
      {!!text && <BodyRegular>{renderTextWithLineBreaks(text)}</BodyRegular>}
      {children}
      {!!link && (
        <Link
          isCompact
          {...link}
          TextComponent={CalloutSemibold}
          noResponsive
          id={link?.button ? 'textimage-cta' : 'textimage-link'}
        />
      )}
    </Inner>
    {!!image && (
      <>
        <Spacer />
        <ImgOuter>
          <Image
            asset={image}
            // maxWidth={640}
            maxHeight={imageHeight}
            style={imageStyle}
          />
          {imageCaption}
        </ImgOuter>
      </>
    )}
  </Outer>
)

export default TextImage
