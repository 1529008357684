import React from 'react'
import styled from 'styled-components'

import type { TextImageFragment } from '../../../graphql-types'
import { MarkdownRenderer } from '../Markdown'
import _TextImage from '../TextImage'
import { BodyRegular, bodyRegularStyle } from '../typography'

import { Bullet as _Bullet, List as _List } from './TasksList'

const Bullet = styled(_Bullet)`
  & + & {
    margin-top: 8px;
  }
`

const List = styled(_List)`
  margin-top: 16px;
`

const Caption = styled.caption`
  ${bodyRegularStyle};
  display: block;
`

type Props = TextImageFragment

const TextImage = ({
  title,
  text,
  link,
  list,
  image,
  layout,
  imageHeight,
  imageCaption,
}: Props) => (
  <_TextImage
    title={title}
    text={text?.text}
    link={link}
    reversed={!layout}
    image={image}
    imageHeight={imageHeight}
    imageCaption={
      !!imageCaption?.markdown && (
        <Caption>
          <MarkdownRenderer>{imageCaption.markdown}</MarkdownRenderer>
        </Caption>
      )
    }
  >
    {!!list?.length && (
      <List>
        {list?.map(
          (text, index) =>
            !!text && (
              <Bullet key={index}>
                <BodyRegular>{text}</BodyRegular>
              </Bullet>
            ),
        )}
      </List>
    )}
  </_TextImage>
)

export default TextImage
