import React, { useState } from 'react'
import { Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/autoplay'
import { Swiper, SwiperSlide } from 'swiper/react'

import type {
  FullAssetFragment,
  ImagesCarouselFragment,
  Maybe,
} from '../../../../graphql-types'

import {
  Breadcrumb,
  CarouselWrapper,
  ImgBreadcrumbsContainer,
  Image,
  Container,
} from './styled'

const SWIPER_MODULES = [Autoplay]
const SWIPER_AUTOPLAY = {
  delay: 2500,
  disableOnInteraction: false,
  pauseOnMouseEnter: false,
}

const handleDragStart = (e: any) => e.preventDefault()

type ImgBreadcrumbsProps = {
  currentIndex: number
  items?: Maybe<Maybe<FullAssetFragment>[]>
}

const ImgBreadcrumbs = ({ currentIndex, items }: ImgBreadcrumbsProps) => (
  <ImgBreadcrumbsContainer>
    {items?.map((_img, index) => (
      <Breadcrumb active={index === currentIndex} key={index} />
    ))}
  </ImgBreadcrumbsContainer>
)

type Props = ImagesCarouselFragment

const ImagesCarousel = ({
  items,
  fullScreenWidth,
  height,
  tabletHeight,
  mobileHeight,
  loop,
  spaceBetween,
  theme,
}: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const carousel = (
    <CarouselWrapper>
      <Swiper
        modules={SWIPER_MODULES}
        autoplay={SWIPER_AUTOPLAY}
        slidesPerView={'auto'}
        centeredSlides={loop || undefined}
        spaceBetween={spaceBetween || 0}
        loop={loop || undefined}
        onSlideChange={(swiper) => setCurrentIndex(swiper.realIndex)}
      >
        {items?.map(
          (image, index) =>
            !!image && (
              <SwiperSlide key={index} style={{ width: 'auto' }}>
                <Image
                  theme={theme}
                  asset={image}
                  onDragStart={handleDragStart}
                  height={height || undefined}
                  tabletHeight={tabletHeight || undefined}
                  mobileHeight={mobileHeight || undefined}
                />
              </SwiperSlide>
            ),
        )}
      </Swiper>
      {/* { Breadcrumbs only visible in the "rounded" theme in mobile } */}
      {theme === 'rounded' && (
        <ImgBreadcrumbs items={items} currentIndex={currentIndex} />
      )}
    </CarouselWrapper>
  )

  if (fullScreenWidth) {
    return carousel
  } else {
    return <Container>{carousel}</Container>
  }
}

export default ImagesCarousel
