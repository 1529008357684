import styled, { css } from 'styled-components'

import { maxScreenWidth, tablet } from '../../mixins'
import { heading3Style, calloutRegularStyle } from '../../typography'

const ITEM_GAP = 24
const FOUR_PER_ROW_ADJUSMENT = ITEM_GAP * (3 / 4)
const TWO_PER_ROW_ADJUSMENT = ITEM_GAP * (1 / 2)
export const ItemOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 24px;
  box-shadow: 0px 12px 32px rgba(98, 104, 111, 0.2);
  border-radius: 32px;
  flex-basis: calc(25% - ${FOUR_PER_ROW_ADJUSMENT}px);
  ${tablet(css`
    flex-basis: calc(50% - ${TWO_PER_ROW_ADJUSMENT}px);
  `)}
  ${maxScreenWidth(580)(css`
    flex-basis: 100%;
  `)}
`

export const Title = styled.span`
  margin-bottom: 8px;
  ${heading3Style({ noResponsive: true })}
`

export const Emoji = styled.span`
  margin-bottom: 24px;
  font-size: 32px;
`
export const EmojiImg = styled.img`
  margin-bottom: 24px;
  height: 32px;
`

export const Text = styled.p`
  ${calloutRegularStyle({ noResponsive: true })}
  margin-top: 8px;
`

export const Outer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${ITEM_GAP}px;
`
