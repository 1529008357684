import { useEffect } from 'react'

import type { StoreSessionValueFragment } from '../../../graphql-types'
import { setStorageParam } from '../../hooks/useQueryOrStorageParam'

type Props = StoreSessionValueFragment

const StoreSessionValue = ({ key, value }: Props) => {
  useEffect(() => {
    if (key && typeof value === 'string') {
      setStorageParam(key, value)
    }
  }, [key, value])

  // Do not render anything
  return null
}

export default StoreSessionValue
