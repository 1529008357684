import type { ComponentProps } from 'react'
import React from 'react'
import styled, { css } from 'styled-components'

import type {
  PressItemFragment,
  PressListFragment,
} from '../../../graphql-types'
import { mobile } from '../mixins'
import { Heading3 } from '../typography'

import Img from './Img'

const ItemOuter = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px;
  margin: 0 12px;
  min-height: 400px;
  box-shadow: 0px 12px 32px rgba(98, 104, 111, 0.2);
  border-radius: 32px;
  max-width: 360px;
  min-width: 300px;
  flex: 1 1 300px;

  @media screen and (max-width: 711px) {
    & + & {
      margin-top: 24px;
    }
  }

  ${mobile(css`
    padding: 32px;
    min-height: 320px;
  `)}
`

const Logo = styled(Img)``

type ItemProps = PressItemFragment & ComponentProps<typeof ItemOuter>

const PressItem = ({ quote, url, logo }: ItemProps) => (
  <ItemOuter href={url || ''} target={'_blank'} id={'press-link'}>
    <Heading3>{`"${quote?.quote}"`}</Heading3>
    <Logo asset={logo} />
  </ItemOuter>
)

const Outer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

type Props = PressListFragment

const PressList = ({ items }: Props) => (
  <Outer>
    {items?.map((item, index) => !!item && <PressItem key={index} {...item} />)}
  </Outer>
)

export default PressList
