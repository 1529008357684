import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import type { FaqSectionsQuery } from '../../../../graphql-types'
import { maxScreenWidth } from '../../mixins'
import Module from '../../modules'
import { Color } from '../../theme'
import {
  Heading2,
  calloutRegularStyle,
  CalloutSemibold,
} from '../../typography'
import Container from '../Container'

const MAX_WIDTH = 1024
const SCROLL_TOP = 260 - 72

const TEMP_ID = Math.random().toString()
const SIDEBAR_ID = `sidebar-${TEMP_ID}`
const SECTION_ID_PREFIX = `section-${TEMP_ID}-`
const SECTION_ID = (slug: string): string => `${SECTION_ID_PREFIX}${slug}`

export const query = graphql`
  query FaqSections {
    list: allContentfulFaqSection(
      sort: { fields: order, order: ASC }
      filter: { node_locale: { eq: "en" } }
    ) {
      items: nodes {
        title
        slug
        paragraphs {
          ...Text
        }
      }
    }
  }
`

const Outer = styled(Container)`
  display: flex;
  flex-direction: row;
  max-width: ${MAX_WIDTH}px;
`

const Sidebar = styled.div`
  margin-right: ${(48 / MAX_WIDTH) * 100}%;
  flex: 0 0 ${(256 / MAX_WIDTH) * 100}%;
  display: flex;
  position: relative;
  flex-direction: column;
  ${maxScreenWidth(700)(css`
    display: none;
  `)}
`

const SidebarTitle = styled(CalloutSemibold)`
  padding: 28px 12px 18px;
`

const Inner = styled.div`
  flex: 1 0 0;
`

const NavLink = styled.a<{ active?: boolean }>`
  ${calloutRegularStyle({})};
  padding: 7px 12px;
  border-radius: 12px;

  ${({ active }) =>
    !!active &&
    css`
      background-color: ${Color.GRAY_1};
    `}
`

const Section = styled.div``

const SectionAnchor = styled.a`
  position: relative;
  top: -${72 + 24}px;
`

const SectionTitle = styled(Heading2)`
  padding: 24px 0;
`

const onScrollHandler = (setCurrentSlug: (slug: string) => void) => {
  let lastScrollYY = 0
  let ticking = false

  return () => {
    lastScrollYY = window.scrollY // eslint-disable-line

    if (!ticking) {
      window.requestAnimationFrame(() => {
        const sidebar = document.getElementById(SIDEBAR_ID)
        if (sidebar) {
          sidebar.style.paddingTop = `${Math.max(
            0,
            lastScrollYY - SCROLL_TOP,
          )}px`
        }

        const windowHeight = window.innerHeight
        const max = {
          visible: 0,
          target: null,
        }
        const sectionEls = document.getElementsByClassName(SECTION_ID_PREFIX)
        Array.prototype.forEach.call(sectionEls, function (target) {
          const rect = target.getBoundingClientRect()
          const visible =
            Math.min(windowHeight, rect.bottom) - Math.max(0, rect.top)
          if (visible > max.visible) {
            max.visible = visible
            max.target = target
          }
        })

        if (max.target) {
          // @ts-ignore
          setCurrentSlug(max.target.id.substr(SECTION_ID_PREFIX.length))
        }

        ticking = false
      })
      ticking = true
    }
  }
}

const FaqSections = () => {
  const [didRedirect, setDidRedirect] = useState(false)
  const data = useStaticQuery<FaqSectionsQuery>(query)
  const [currentSlug, setCurrentSlug] = useState<string | null>(null)

  useEffect(() => {
    const onWindowScroll = onScrollHandler(setCurrentSlug)
    window.addEventListener('scroll', onWindowScroll)

    return () => {
      window.removeEventListener('scroll', onWindowScroll)
    }
  }, [setCurrentSlug])

  const sections = data?.list.items

  // Make sure to navigate to anchor on initial load
  useEffect(() => {
    if (!didRedirect) {
      if (window.location.hash) {
        const l = window.location.pathname + window.location.hash
        setTimeout(() => {
          window.location.href = l
        }, 100)
      }
      setDidRedirect(true)
    }
  }, [didRedirect, setDidRedirect])

  if (!sections) {
    return null
  }

  return (
    <Outer>
      <Sidebar id={SIDEBAR_ID}>
        <SidebarTitle>{'FAQ'}</SidebarTitle>
        {sections.map(
          (section) =>
            !!section && (
              <NavLink
                key={section.slug}
                href={`#${section.slug}`}
                active={currentSlug === section.slug}
              >
                {section.title}
              </NavLink>
            ),
        )}
      </Sidebar>
      <Inner>
        {sections.map(
          (section) =>
            !!section && (
              <Section
                key={section.slug}
                id={SECTION_ID(section.slug || '')}
                className={SECTION_ID_PREFIX}
              >
                <SectionAnchor id={section.slug || ''} />
                <SectionTitle>{section.title}</SectionTitle>
                {section.paragraphs?.map(
                  (text, index) =>
                    !!text && <Module key={index} module={text} noContainer />,
                )}
              </Section>
            ),
        )}
      </Inner>
    </Outer>
  )
}

export default FaqSections
