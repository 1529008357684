import React from 'react'

import type {
  HeroFragment,
  LargeImageFragment,
  IconsListFragment,
  PeopleFragment,
  PressListFragment,
  ContentfulPricingListFragment,
  SpacerFragment,
  TasksListFragment,
  TestimonialFragment,
  TextFragment,
  TextImageFragment,
  TimelineItemFragment,
  CustomModuleFragment,
  ReviewsListFragment,
  CardsListFragment,
  VideoFragment,
  ImagesCarouselFragment,
  StoreSessionValueFragment,
  CalendlyFragment,
  CalendarDayFragment,
  PullQuoteFragment,
  StatisticsFragment,
  VignettesListFragment,
  TagsImageListFragment,
} from '../../../graphql-types'

import CalendarDay from './CalendarDay'
import Calendly from './Calendly'
import CardsList from './CardsList'
import Container from './Container'
import ContentfulPricingList from './ContentfulPricingList'
import CustomModule from './CustomModule'
import Hero from './Hero'
import IconsList from './IconsList'
import ImagesCarousel from './ImagesCarousel'
import Img from './Img'
import LargeImage from './LargeImage'
import People from './People'
import PressList from './PressList'
import PullQuote from './PullQuote'
import ReviewsList from './ReviewsList'
import Spacer from './Spacer'
import Statistics from './Statistics'
import StoreSessionValue from './StoreSessionValue'
import TagsImageList from './TagsImageList'
import TasksList from './TasksList'
import Testimonial from './Testimonial'
import Text from './Text'
import TextImage from './TextImage'
import TimelineItem from './TimelineItem'
import Video from './Video'
import VignettesList from './VignettesList'

export {
  Container,
  Hero,
  Spacer,
  LargeImage,
  TextImage,
  TimelineItem,
  Text,
  Testimonial,
  TasksList,
  ContentfulPricingList,
  PressList,
  People,
  IconsList,
  CustomModule,
  Img,
  CardsList,
  ReviewsList,
  Video,
  ImagesCarousel,
  StoreSessionValue,
  Calendly,
  CalendarDay,
  PullQuote,
  Statistics,
  VignettesList,
  TagsImageList,
}

type ModuleFragment =
  | HeroFragment
  | LargeImageFragment
  | IconsListFragment
  | PeopleFragment
  | PressListFragment
  | ReviewsListFragment
  | CardsListFragment
  | VideoFragment
  | ImagesCarouselFragment
  | ContentfulPricingListFragment
  | SpacerFragment
  | TasksListFragment
  | TestimonialFragment
  | TextFragment
  | TextImageFragment
  | TimelineItemFragment
  | CustomModuleFragment
  | StoreSessionValueFragment
  | CalendlyFragment
  | CalendarDayFragment
  | PullQuoteFragment
  | StatisticsFragment
  | VignettesListFragment
  | TagsImageListFragment

type ModuleProps = {
  module?: ModuleFragment | null
  noContainer?: boolean
}

const RawModule = ({ module }: ModuleProps) => {
  if (!module?.__typename) {
    return null
  }

  switch (module.__typename) {
    case 'ContentfulPageHero':
      return <Hero {...module} />
    case 'ContentfulLargeImage':
      return <LargeImage {...module} />
    case 'Contentful3Icons':
      return <IconsList {...module} />
    case 'ContentfulPeople':
      return <People {...module} />
    case 'ContentfulPressList':
      return <PressList {...module} />
    case 'ContentfulReviewsList':
      return <ReviewsList {...module} />
    case 'ContentfulCardsList':
      return <CardsList {...module} />
    case 'ContentfulVideo':
      return <Video {...module} />
    case 'ContentfulImagesCarousel':
      return <ImagesCarousel {...module} />
    case 'ContentfulPricingList':
      return <ContentfulPricingList {...module} />
    case 'ContentfulTextImage':
      return <TextImage {...module} />
    case 'ContentfulSpacer':
      return <Spacer {...module} />
    case 'ContentfulTasksList':
      return <TasksList {...module} />
    case 'ContentfulTestimonial':
      return <Testimonial {...module} />
    case 'ContentfulTimelineItem':
      return <TimelineItem {...module} />
    case 'ContentfulText':
      return <Text {...module} />
    case 'ContentfulCustomModule':
      return <CustomModule {...module} />
    case 'ContentfulStoreSessionValue':
      return <StoreSessionValue {...module} />
    case 'ContentfulCalendly':
      return <Calendly {...module} />
    case 'ContentfulCalendarDay':
      return <CalendarDay {...module} />
    case 'ContentfulPullQuote':
      return <PullQuote {...module} />
    case 'ContentfulStatistics':
      return <Statistics {...module} />
    case 'ContentfulVignettesList':
      return <VignettesList {...module} />
    case 'ContentfulTagsImageList':
      return <TagsImageList {...module} />
  }
}

const NO_CONTAINER: ModuleFragment['__typename'][] = [
  'ContentfulLargeImage',
  'ContentfulCustomModule',
  'ContentfulImagesCarousel',
  'ContentfulCalendly',
]

const Module = ({ module, noContainer }: ModuleProps) => {
  const moduleElement = <RawModule module={module} />

  if (
    noContainer ||
    (module?.__typename && NO_CONTAINER.includes(module.__typename))
  ) {
    return moduleElement
  }

  return <Container>{moduleElement}</Container>
}

export default Module
