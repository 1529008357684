import { Widget as _Widget } from '@typeform/embed-react'
import type { ComponentProps } from 'react'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import useGclid from '../../../hooks/useGclid'
import useHubspotutk from '../../../hooks/useHubspotutk'
import useQueryOrStorageParam, {
  setStorageParam,
} from '../../../hooks/useQueryOrStorageParam'
import { mobile } from '../../mixins'
import Container from '../Container'

const PARAMS_STORAGE_KEY = 'tfParams'

export function setTypeformParams(params: Record<string, string>) {
  setStorageParam(PARAMS_STORAGE_KEY, JSON.stringify(params), false)
}

export function useStoredParams(): Record<string, string> {
  const storedValue = useQueryOrStorageParam(PARAMS_STORAGE_KEY, '{}')

  try {
    return JSON.parse(storedValue) as Record<string, string>
  } catch (error) {
    console.debug('Error parsing JSON from storage')
    return {}
  }
}

const Widget = styled(_Widget)<{ height: string }>`
  background: white;
  z-index: 1;
  width: 100%;
  height: ${({ height }) => height};

  ${mobile(css`
    height: 60vh;
  `)}
`

type Props = ComponentProps<typeof Widget> & {
  formId?: string | null | undefined
  height?: string | null | undefined
  hideProgressBar?: boolean | null | undefined
  hideHeader?: boolean | null | undefined
  onSubmit?: () => void
}

const Typeform = ({
  formId,
  height,
  hideHeader,
  hideProgressBar,
  onSubmit,
  ...props
}: Props) => {
  const [params, setParams] = useState<Record<string, string>>({})
  const gclid = useGclid()
  const storedParams = useStoredParams()
  const hubspotutk = useHubspotutk()

  useEffect(() => {
    try {
      if (window.location.search) {
        // disable lint since we import a URLSearchParams polyfill for IE
        const searchParams = new URLSearchParams(window.location.search) // eslint-disable-line
        setParams((params) => ({
          ...params,
          // @ts-ignore
          ...[...searchParams].reduce(
            (p, [key, value]) => ({ ...p, [key]: value }),
            {},
          ),
        }))
      }
    } catch (error) {
      console.error('Failed to read param from URL query', error)
    }
  }, [setParams])

  useEffect(() => {
    if (gclid) {
      setParams((params) => ({ ...params, gclid }))
    }
  }, [gclid, setParams])

  useEffect(() => {
    if (hubspotutk) {
      /* eslint-disable camelcase */
      setParams((params) => ({
        ...params,
        hubspot_utk: hubspotutk,
        hubspot_page_name: document.title,
        hubspot_page_url: window.location.href,
      }))
      /* eslint-enable camelcase */
    }
  }, [hubspotutk, setParams])

  useEffect(() => {
    const newParams = { ...params, ...storedParams }
    if (JSON.stringify(newParams) !== JSON.stringify(params)) {
      setParams(newParams)
    }
  }, [storedParams, setParams, params])

  return (
    <Container>
      <Widget
        id={formId}
        hidden={params}
        height={height || '800px'}
        hideFooter={!!hideProgressBar}
        hideHeader={!!hideHeader}
        onSubmit={onSubmit}
        {...props}
      />
    </Container>
  )
}

export default Typeform
