import type { ComponentProps } from 'react'
import React from 'react'
import styled, { css } from 'styled-components'

import type { VideoFragment } from '../../../graphql-types'
import { maxScreenWidth } from '../mixins'

const WIDTH = 800
const DEFAULT_RATIO = 1.5

const videoStyle = ({ ratio }: { ratio: number }) => css`
  border-radius: 32px;
  margin: 0 auto;
  max-width: 100%;

  ${maxScreenWidth(750)(css`
    max-height: ${700 / ratio}px;
  `)}
  ${maxScreenWidth(650)(css`
    max-height: ${600 / ratio}px;
  `)}
  ${maxScreenWidth(550)(css`
    max-height: ${500 / ratio}px;
  `)}
  ${maxScreenWidth(450)(css`
    max-height: ${400 / ratio}px;
  `)}
`

const Iframe = styled.iframe<{ ratio: number }>`
  ${videoStyle}
`

const VideoTag = styled.video<{ ratio: number }>`
  ${videoStyle}
`

type Props = VideoFragment & ComponentProps<typeof Iframe>

const Video = ({ __typename, youtubeId, video, ratio, ...props }: Props) => {
  if (video) {
    return (
      <VideoTag
        ratio={ratio || DEFAULT_RATIO}
        controls
        controlsList={'nodownload'}
        preload={'metadata'}
        width={WIDTH}
        height={WIDTH / (ratio || DEFAULT_RATIO)}
      >
        <source src={video.file.url} type={video.file.contentType} />
      </VideoTag>
    )
  }

  return (
    <Iframe
      ratio={ratio}
      src={`https://www.youtube-nocookie.com/embed/${youtubeId}?controls=0`}
      allow={
        'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      }
      allowfullscreen
      frameBorder={0}
      width={WIDTH}
      height={WIDTH / (ratio || DEFAULT_RATIO)}
      {...props}
    />
  )
}

export default Video
