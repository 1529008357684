import type { ComponentProps } from 'react'
import React from 'react'
import styled, { css } from 'styled-components'

import type { CalendarDayFragment } from '../../../graphql-types'
import { mobile } from '../mixins'
import { Color } from '../theme'
import { Heading3, BodySemibold, CalloutSemibold } from '../typography'

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 24px;

  ${mobile(css`
    padding: 24px 0;
    width: 100%;
  `)}
`

const Title = styled(Heading3)`
  margin-bottom: 16px;
  text-align: center;
`

const Event = styled.div`
  width: 375px;
  background: ${Color.PURPLE_1};
  border-radius: 16px;
  padding: 24px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  & + & {
    margin-top: 16px;
  }

  ${mobile(css`
    width: 100%;
  `)}
`

const EventName = styled(BodySemibold)``

const EventTime = styled(CalloutSemibold)`
  color: ${Color.PURPLE_6};
  margin-left: 16px;
  text-align: right;
`

type Props = CalendarDayFragment & ComponentProps<typeof Outer>

const CalendarDay = ({ __typename, title, items, ...props }: Props) => (
  <Outer {...props}>
    {!!title && <Title>{title}</Title>}
    {items?.map((event, index) => (
      <Event key={index}>
        <EventName>{event?.title}</EventName>
        <EventTime>{event?.time}</EventTime>
      </Event>
    ))}
  </Outer>
)

export default CalendarDay
