import type { ComponentProps } from 'react'
import React from 'react'
import styled, { css } from 'styled-components'

import { maxScreenWidth } from '../mixins'
import { Color } from '../theme'
import { CalloutRegular, CalloutSemibold, BodyMedium } from '../typography'

const ItemOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 40px 32px 40px;
  margin: 0 12px;
  box-shadow: 0px 12px 32px rgba(98, 104, 111, 0.2);
  border-radius: 32px;
  flex: 1 1 21%;
  max-width: 360px;

  ${maxScreenWidth(1020)(css`
    flex-basis: 40%;
    :nth-child(n + 3) {
      margin-top: 24px;
    }
  `)}

  ${maxScreenWidth(580)(css`
    flex-basis: 90%;
    :nth-child(n + 2) {
      margin-top: 24px;
    }
  `)}
`

const TestimonyTitle = styled(CalloutSemibold)``
const TestimonySubtitle = styled(CalloutRegular)``

const TestimonyText = styled(BodyMedium)`
  padding: 0 0 32px 0;
`

const TestimonyAuthor = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 52px;
  align-items: center;
`

const AuthorText = styled.div``

const AuthorLogo = styled.div`
  overflow: hidden;
  width: 45px;
  height: 45px;
  background: ${Color.PURPLE_1};
  border-radius: 10px;
  margin-right: 16px;
`

const AuthorLogoImage = styled.img`
  width: 45px;
  height: 45px;
`

interface Item {
  text: string
  title: string
  subtitle?: string
  logo?: string
}

type ItemProps = Item & ComponentProps<typeof ItemOuter>

const TestimonyItem = ({ subtitle, text, title, logo }: ItemProps) => (
  <ItemOuter>
    <TestimonyText noResponsive>
      {'“'}
      {text}
      {'”'}
    </TestimonyText>
    <TestimonyAuthor>
      {logo && (
        <AuthorLogo>
          <AuthorLogoImage src={logo} width={42} height={42} />
        </AuthorLogo>
      )}
      <AuthorText>
        <TestimonyTitle>{title}</TestimonyTitle>
        {subtitle && <TestimonySubtitle>{subtitle}</TestimonySubtitle>}
      </AuthorText>
    </TestimonyAuthor>
  </ItemOuter>
)

const Outer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`

interface Props {
  items: Item[]
}

const TestimonyList = ({ items }: Props) => (
  <Outer>
    {items.map(
      (item, index) => !!item && <TestimonyItem key={index} {...item} />,
    )}
  </Outer>
)

export default TestimonyList
