import React from 'react'

import type { CustomModuleFragment } from '../../../../graphql-types'

import CompareTable from './CompareTable'
import DelegationCalculator from './DelegationCalculator'
import DiversityAndEquity from './DiversityAndEquity'
import FaqSections from './FaqSections'
import JobsList from './JobsList'
import PricingsList from './PricingsList'
import Typeform from './Typeform'
import WhatWeOffer from './WhatWeOffer'

type Props = CustomModuleFragment

const CustomModule = ({ id, params, imageList }: Props) => {
  switch (id) {
    case 'jobs-list':
      return (
        <JobsList
          ctaUrl={params?.ctaUrl}
          assistantInfo={params?.assistantInfo}
        />
      )
    case 'compare-table':
      return <CompareTable />
    case 'faq-sections':
      return <FaqSections />
    case 'delegation-calculator':
      return <DelegationCalculator />
    case 'pricings-list':
      return <PricingsList ctaUrl={params?.ctaUrl || undefined} />
    case 'diversity-and-equity':
      return (
        <DiversityAndEquity
          paragraphTop={params?.paragraphTop}
          paragraphBottom={params?.paragraphBottom}
          initiatives={params?.initiatives}
          imageList={imageList}
        />
      )
    case 'what-we-offer':
      return <WhatWeOffer assistants={params?.assistants} hq={params?.hq} />
    default:
      break
  }

  if (params) {
    const { type, ...otherParams } = params
    if (type === 'typeform') {
      return <Typeform {...otherParams} />
    }
  }

  return null
}

export default CustomModule
