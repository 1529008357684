import type { ComponentProps } from 'react'
import React, { useEffect, useRef } from 'react'
import useScript from 'react-script-hook'
import styled, { css } from 'styled-components'

import type { CalendlyFragment } from '../../../graphql-types'
import useQueryOrStorageParam from '../../hooks/useQueryOrStorageParam'
import { Container } from '../layout'
import { maxScreenWidth } from '../mixins'
import { trackEvent } from '../utils'

const CALENDLY_ALICE = 'https://calendly.com/alicedefault/intro'
const CALENDLY_US = 'https://calendly.com/double-intro/double-intro'
export const getCalendlyUrlForCountryCode = (
  countryCode: string | undefined | null,
): string => (countryCode === 'FR' ? CALENDLY_ALICE : CALENDLY_US)

const Inner = styled.div`
  flex: 1;
  height: 630px;
  margin-bottom: 100px;

  ${maxScreenWidth(1070)(css`
    height: 1000px;
  `)}

  ${maxScreenWidth(780)(css`
    height: 1050px;
  `)}

  ${maxScreenWidth(713)(css`
    margin-top: 24px;
    height: 1000px;
  `)}
`

// https://help.calendly.com/hc/en-us/articles/360020052833-Advanced-embed-options
// @ts-ignore
const ON_CALENDLY_MESSAGE = ({
  data: { event },
}: MessageEvent<{ event: string; payload: { event: { uri: string } } }>) => {
  if (event === 'calendly.date_and_time_selected') {
    trackEvent('select_call_time')
  } else if (event === 'calendly.event_scheduled') {
    trackEvent('confirm_call_scheduling')
    // There should be a payload.event.uri like "https://api.calendly.com/scheduled_events/XYZ"

    // Track event
    trackEvent('intro_call')
  }
}

type Props = Partial<CalendlyFragment> &
  ComponentProps<typeof Container> & {
    name?: string
    email?: string
    phone?: string
    countryCode?: string
  }

const Calendly = ({ countryCode, ...allProps }: Props) => {
  const defaultName = useQueryOrStorageParam('name')
  const defaultEmail = useQueryOrStorageParam('email')
  const defaultPhone = useQueryOrStorageParam('phone')

  const {
    __typename,
    url = getCalendlyUrlForCountryCode(countryCode),
    name = defaultName,
    email = defaultEmail,
    phone = defaultPhone,
    ...props
  } = allProps

  const calendlyContainer = useRef<typeof Inner>()
  const [loadingCalendly, errorLoadingCalendly] = useScript({
    src: 'https://proxy.withdouble.com/assets.calendly.com/assets/external/widget.js',
  })

  const calendlyOk = !loadingCalendly && !errorLoadingCalendly

  useEffect(() => {
    if (!calendlyContainer.current || !calendlyOk || !window.Calendly) {
      return
    }

    // Initialize Calendly widget
    // @ts-ignore
    window.Calendly.initInlineWidget({
      parentElement: calendlyContainer.current,
      url,
      prefill: {
        name,
        email,
        customAnswers: {
          a1: phone,
        },
      },
    })

    // Track to Google Analytics
    trackEvent('open_call_scheduling')

    // Listen to Calendly widget messages
    window.addEventListener('message', ON_CALENDLY_MESSAGE)
    return () => window.removeEventListener('message', ON_CALENDLY_MESSAGE)
  }, [calendlyContainer.current, name, email, phone, url])

  return (
    <Container close {...props}>
      <Inner
        // @ts-ignore
        ref={calendlyContainer}
        className={'calendly-inline-widget'}
        data-auto-load={false}
      />
    </Container>
  )
}

export default Calendly
