import React from 'react'
import styled, { css } from 'styled-components'

import type {
  ContentfulPricingItemFragment,
  ContentfulPricingListFragment,
} from '../../../graphql-types'
import { desktop, maxScreenWidth, mobile } from '../mixins'
import { Color } from '../theme'
import {
  Heading3,
  SubheadRegular,
  CalloutRegular,
  BodyMedium,
  subheadRegularStyle,
  heading3Style,
  calloutRegularStyle,
} from '../typography'

import Link from './Link'
import { List, Bullet } from './TasksList'

const COLUMN_MAX_WIDTH = 890

interface ItemOuterProps {
  selectable?: boolean
  isSelected?: boolean
}

const ItemOuter = styled.div<ItemOuterProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 29px 24px;
  margin: 12px 12px;
  min-height: 360px;
  box-shadow: 0px 12px 32px rgba(98, 104, 111, 0.2);
  border-radius: 32px;
  max-width: 360px;
  flex: 1 1 250px;

  border: ${({ isSelected }) =>
    `2px solid ${isSelected ? Color.PURPLE_5 : 'transparent'}`};
  cursor: ${({ selectable }) => (selectable ? 'pointer' : 'default')};

  ${maxScreenWidth(1160)(css`
    min-width: 45%;
  `)}

  ${maxScreenWidth(COLUMN_MAX_WIDTH)(css`
    flex: 0 0 auto;
    max-width: unset;
    min-height: 270px;
    padding: 32px;
    & + & {
      margin-top: 24px;
    }
  `)}
`

const Label = styled(SubheadRegular)`
  color: ${Color.GRAY_6};
`
const Price = styled(Heading3)`
  margin: 0 0 16px !important;
  max-width: 100%;

  ${maxScreenWidth(COLUMN_MAX_WIDTH)(css`
    margin-bottom: 24px !important;
  `)}
`
const Hours = CalloutRegular

const Description = styled(CalloutRegular)`
  min-height: 68px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const Features = styled(List)`
  margin-top: 16px;
`

interface SelectLabelProps {
  isSelected: boolean
}

const SelectLabel = styled(BodyMedium)<SelectLabelProps>`
  color: ${Color.PURPLE_5};
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  align-self: center;
  margin-top: 30px;

  opacity: ${({ isSelected }) => (isSelected ? '0' : '1')};
`

export type ItemProps = Pick<
  ContentfulPricingItemFragment,
  'label' | 'price' | 'hours' | 'features'
> & {
  id?: string
  description?: string
  selectable?: boolean
  isSelected?: boolean
  onSelect?: () => void
}

const PricingItem = ({
  id,
  label,
  price,
  hours,
  description,
  features,
  selectable,
  isSelected,
  onSelect,
}: ItemProps): JSX.Element => (
  <ItemOuter
    className={'pricing'}
    id={id || `pricing-${label?.toLocaleLowerCase()}`}
    onClick={onSelect}
    selectable={selectable}
    isSelected={isSelected}
  >
    <Label noColor noResponsive>
      {label}
    </Label>
    <Price noResponsive>{price}</Price>
    {description && <Description>{description}</Description>}
    <Hours noResponsive>{hours}</Hours>
    {!!features?.length && (
      <Features>
        {features?.map(
          (text, index) =>
            !!text && (
              <Bullet key={index}>
                <CalloutRegular noResponsive>{text}</CalloutRegular>
              </Bullet>
            ),
        )}
      </Features>
    )}
    {selectable && (
      <SelectLabel isSelected={!!isSelected}>{'Select'}</SelectLabel>
    )}
  </ItemOuter>
)

const Outer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${maxScreenWidth(COLUMN_MAX_WIDTH)(css`
    flex-direction: column;
  `)}
`

const TopBanner = styled.div`
  box-shadow: 0px 12px 32px rgba(98, 104, 111, 0.2);
  border-radius: 32px;
  margin: 12px 12px;
  padding: 32px 24px;
  justify-content: space-between;
  display: flex;
  ${desktop(`
  align-items: center;
  flex-direction: row;
  column-gap: 20px;
  `)}
  ${mobile(`
  flex-direction: column;
  row-gap: 20px;
  `)}
`

const TopBannerTitle = styled.div`
  ${subheadRegularStyle({ noColor: true })};
  color: ${Color.GRAY_6};
`

const TopBannerSubtitle = styled.div`
  ${heading3Style};
  color: ${Color.BLACK};
  ${desktop(`
  max-width: 257px;
  `)}
  ${mobile(`
  max-width: 190px;
  `)}
`

const TopBannerText = styled.div`
  ${calloutRegularStyle};
  ${desktop(`
  max-width: 379px;
  `)}
`

const SeparatorContainer = styled.div`
  margin: 12px 12px;
`

const SeparatorDesktop = styled.div`
  margin: 32px 0;
  width: 100%;
  border-bottom: solid 1px ${Color.GRAY_3};

  ${mobile(`
  display: none;
  `)}
`

const SeparatorMobile = styled.div`
  margin: 24px 0;
  width: 50%;
  height: 60px;
  border-right: solid 1px ${Color.GRAY_3};

  ${desktop(`
  display: none;
  `)}
`

const SeparatorLabel = styled.div`
  ${subheadRegularStyle({ noColor: true, noResponsive: true })};
  color: ${Color.GRAY_6};
  background-color: #ffffff;
  z-index: 2;
  text-align: center;
  ${desktop(`
  width: max-content;
  padding: 0 32px;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  `)}
  ${mobile(`
  margin: -20px;
  `)}
`

const PricingList = ({
  items,
  separatorLabel,
  topBannerCta,
  topBannerSubtitle,
  topBannerText,
  topBannerTitle,
}: ContentfulPricingListFragment): JSX.Element => (
  <>
    <TopBanner>
      {topBannerTitle && topBannerSubtitle && (
        <div>
          {topBannerTitle && <TopBannerTitle>{topBannerTitle}</TopBannerTitle>}

          {topBannerSubtitle && (
            <TopBannerSubtitle>{topBannerSubtitle}</TopBannerSubtitle>
          )}
        </div>
      )}

      {topBannerText && <TopBannerText>{topBannerText}</TopBannerText>}

      {!!topBannerCta && <Link {...topBannerCta} isCompact />}
    </TopBanner>

    {separatorLabel && (
      <SeparatorContainer>
        <SeparatorDesktop />
        <SeparatorMobile />
        <SeparatorLabel>{separatorLabel}</SeparatorLabel>
        <SeparatorMobile />
      </SeparatorContainer>
    )}

    <Outer id={'pricings'}>
      {items?.map(
        (item, index) => !!item && <PricingItem key={index} {...item} />,
      )}
    </Outer>
  </>
)

export default PricingList
