import { navigate } from 'gatsby'
import capitalize from 'lodash/capitalize'
import React, { useCallback, useReducer, useRef, useState } from 'react'

import { NEW_SIGNUP_PATH } from '../../../../constants'
import { getFormValues } from '../../../forms'
import TestimonyList from '../../../modules/TestimonyList'
import SliderBox from '../../../sliders/SliderBox'
import { trackEvent } from '../../../utils'

import Modal from './Modal'
import RadioChoice from './RadioChoice'
import alanImage from './images/alan.png'
import bettertomorrowventuresImage from './images/bettertomorrowventures.png'
import datawalletImage from './images/datawallet.png'
import edwardlandoImage from './images/edwardlando.png'
import jourImage from './images/jour.png'
import jumboImage from './images/jumbo.png'
import simplifiedImage from './images/simplified.png'
import tandemImage from './images/tandem.png'
import workwellImage from './images/workwell.png'
import {
  SectionSubtitle,
  ListTitle,
  ListItem,
  ModalButton,
  SectionHeader,
  Inner,
  Outer,
  Section,
  ContinueButton,
  ResultSection,
  Computed,
  Archetype,
  And,
  SelectInput,
  CenteredSubheadRegular,
  CenteredHeading2,
  Centered,
} from './styled'

enum AssistantLevel {
  NEVER = "I've never had an assistant before",
  SOME_EXPERIENCE = 'I have some experience with an assistant',
  DEDICATED = 'I have many years of experience with a dedicated assistant',
}

enum WhatINeed {
  CURIOUS = "I'm just curious to learn more about tech + human assistants",
  THINK_I_NEED = 'I think I need an assistant, but not sure',
  KNOW_WHAT_I_NEED = 'I already know exactly what I need an assistant for',
}

interface Hours {
  scheduling: number
  inbox: number
  admin: number
  personal: number
  contact: number
}

interface Quote {
  text: string
  title: string
  subtitle?: string
  logo?: string
}

enum ArchetypeKind {
  EARLY_STAGE = 'EARLY_STAGE',
  GROWTH_STAGE = 'GROWTH_STAGE',
  TEAM_LEAD = 'TEAM_LEAD',
  MICROENTREPRENEUR = 'MICROENTREPRENEUR',
  VC = 'VC',
  OTHER = 'OTHER',
}

interface ArchetypeData {
  plurial: string
  hours: Hours
  lines: string[]
  testimonies: Quote[]
}

const RAJIV_AYYANGAR_QUOTE: Quote = {
  text: 'Double has gone above and beyond to help our team have more time for connection and focus, and has helped me leverage my time better. While I was out at an offsite, my double made a big save leading to a Fortune-500 pilot. Highly recommended.',
  title: 'Rajiv Ayyangar',
  subtitle: 'CEO of Tandem',
  logo: tandemImage,
}

const BENJAMIN_ZENOU_QUOTE: Quote = {
  text: 'Rebecca helps me with a thousand things... from briefings and expense reports, to coordinating board meetings and sending reports to all my managers. I was going through an overwhelming period and she really allowed me to concentrate on my role as CEO.',
  title: 'Benjamin Zenou',
  subtitle: 'CEO at Simplifield',
  logo: simplifiedImage,
}

const MAXIME_GERMAIN_QUOTE: Quote = {
  text: "I couldn't delegate faster than with Double. I can message my Double right from my inbox, where I live, and from the iOS app too. Practically whatever I'm doing, they made it so my assistant is one tap away.",
  title: 'Maxime Germain',
  subtitle: 'CEO at Jour',
  logo: jourImage,
}

const PIERRE_VALADE_QUOTE: Quote = {
  text: "There’s no more mental overload of all these tasks I know I need to do but never get around to. I can just get them right out of my brain with a voice memo in the Double app. I feel a huge weight off my shoulders, also knowing I'm spending my time on things that add the most value.",
  title: 'Pierre Valade',
  subtitle: 'CEO at Jumbo',
  logo: jumboImage,
}

const JEANCHARLES_SAMUELIAN_QUOTE: Quote = {
  text: "Laurence, my double, brings me peace of mind. I love how I can delegate any task and know it'll be taken care of. Once a task is in the app, I don't have to worry about it anymore.",
  title: 'Jean-Charles Samuelian',
  subtitle: 'CEO at Alan',
  logo: alanImage,
}

const SERAFIN_QUOTE: Quote = {
  text: "Fantastic iOS app, and the voice note feature is the quickest to delegate with compared to any other app I'm using. Tip my hat to what you guys are building. I didn’t expect the app to be so good, it saves me so much time.",
  title: 'Serafin',
  subtitle: 'CEO at DataWallet',
  logo: datawalletImage,
}

const MARIE_SCHNEEGANS_QUOTE: Quote = {
  text: 'I spend less time on admin/emails/meetings, more time with my team and product. Double allowed me to be more present (and have better sleep!) Tech without human touch could NOT have won my trust like this.',
  title: 'Marie Schneegans',
  subtitle: 'CEO at Workwell',
  logo: workwellImage,
}

const EDWARD_LANDO_QUOTE: Quote = {
  text: "What's amazing for me is spending several more hours every week focused on founders, NOT opening my calendar 50 times a day. Relationships are core to early-stage investing, and it's key to have someone I trust to help with inbox & calendar.",
  title: 'Edward Lando',
  subtitle: 'Investor',
  logo: edwardlandoImage,
}

const JAKE_GIBSON_QUOTE: Quote = {
  text: "It's amazing to know quality meetings just appear on my calendar now, clearly prioritized, rescheduling and follow ups completely handled. I have so much more time to promote our portfolio companies & get them quality introductions too.",
  title: 'Jake Gibson',
  subtitle: 'Founding Partner at Better Tomorrow Ventures',
  logo: bettertomorrowventuresImage,
}

const ARCHETYPES: Record<ArchetypeKind, ArchetypeData> = {
  [ArchetypeKind.EARLY_STAGE]: {
    plurial: 'early-stage startup founders',
    lines: [
      '💬 Talk to users',
      '🤝 Close key customers & partners',
      '💵 Laser focus on fundraising and relationships',
      '✌️ Build personal brand & thought leadership for the company',
    ],
    testimonies: [
      RAJIV_AYYANGAR_QUOTE,
      MAXIME_GERMAIN_QUOTE,
      BENJAMIN_ZENOU_QUOTE,
    ],
    hours: {
      scheduling: 4,
      inbox: 5,
      admin: 1,
      contact: 3,
      personal: 1,
    },
  },
  [ArchetypeKind.GROWTH_STAGE]: {
    plurial: 'growth-stage startup founders',
    lines: [
      '🧠 Block off focus time for strategizing',
      '📱 Keep pulse on what users want next',
      '🔍 Find and close world-class talent',
      '👨‍👩‍👦‍👦 Coach team & build culture',
    ],
    testimonies: [SERAFIN_QUOTE, MAXIME_GERMAIN_QUOTE, MARIE_SCHNEEGANS_QUOTE],
    hours: {
      scheduling: 4,
      inbox: 5,
      admin: 2,
      contact: 2,
      personal: 1,
    },
  },
  [ArchetypeKind.TEAM_LEAD]: {
    plurial: 'team leads',
    lines: [
      '📈 Enable their team for success',
      '🎯 Collaborate & secure buy-in around the company',
      '🏆 Stay on top of network & industry best practices',
      '👩‍🎓 Mentor & be mentored',
    ],
    testimonies: [
      MAXIME_GERMAIN_QUOTE,
      JEANCHARLES_SAMUELIAN_QUOTE,
      RAJIV_AYYANGAR_QUOTE,
    ],
    hours: {
      scheduling: 3,
      inbox: 5,
      admin: 2,
      contact: 2,
      personal: 1,
    },
  },
  [ArchetypeKind.MICROENTREPRENEUR]: {
    plurial: 'microentrepreneurs',
    lines: [
      '🔍 Find and close new business',
      '🤝 Network & nurture relationships',
      '🧠 Refocus & find inspiration',
      '🌟 Build great content & personal brand',
    ],
    testimonies: [
      PIERRE_VALADE_QUOTE,
      RAJIV_AYYANGAR_QUOTE,
      MAXIME_GERMAIN_QUOTE,
    ],
    hours: {
      scheduling: 2,
      inbox: 4,
      admin: 2,
      contact: 1,
      personal: 2,
    },
  },
  [ArchetypeKind.VC]: {
    plurial: 'investors',
    lines: [
      '🔍 Find great founders early & often',
      '📈 Support portfolio companies on the ground',
      '🤝 Build relationships & conduct deeper due diligence',
      '👨‍💻 Continuously refine their investment thesis & thought leadership',
    ],
    testimonies: [EDWARD_LANDO_QUOTE, MAXIME_GERMAIN_QUOTE, JAKE_GIBSON_QUOTE],
    hours: {
      scheduling: 2,
      inbox: 4,
      admin: 1,
      contact: 2,
      personal: 1,
    },
  },
  [ArchetypeKind.OTHER]: {
    plurial: 'executives',
    lines: [
      '💬 Talk to users',
      '🤝 Close key customers & partners',
      '💵 Laser focus on fundraising and relationships',
      '✌️ Build personal brand & thought leadership for the company',
    ],
    testimonies: [
      RAJIV_AYYANGAR_QUOTE,
      MAXIME_GERMAIN_QUOTE,
      BENJAMIN_ZENOU_QUOTE,
    ],
    hours: {
      scheduling: 4,
      inbox: 5,
      admin: 1,
      contact: 3,
      personal: 1,
    },
  },
}

const INITIAL_STATE: State = {
  archetype: ArchetypeKind.EARLY_STAGE,
  ...ARCHETYPES[ArchetypeKind.EARLY_STAGE].hours,
}

const OPTIONS = [
  {
    label: '🐣  Early-stage startup founder (pre-seed, seed)',
    value: ArchetypeKind.EARLY_STAGE,
  },
  {
    label: '🦁  Growth-stage startup founder (Series A+)',
    value: ArchetypeKind.GROWTH_STAGE,
  },
  {
    label: '🐘  Team lead (C-level, VP, Director, Manager...)',
    value: ArchetypeKind.TEAM_LEAD,
  },
  {
    label: '🐭  Microentrepreneur (you are the company!)',
    value: ArchetypeKind.MICROENTREPRENEUR,
  },
  { label: '💸  Investor (VC)', value: ArchetypeKind.VC },
  {
    label: '🤓  None of these, but still very busy',
    value: ArchetypeKind.OTHER,
  },
]

const HOURS_KEYS = ['scheduling', 'inbox', 'admin', 'personal', 'contact']

const HOURS_MULTIPLICATORS = {
  scheduling: 1,
  inbox: 0.3,
  admin: 0.7,
  contact: 0.4,
  personal: 0.5,
}

interface State {
  archetype: ArchetypeKind
  scheduling: number
  inbox: number
  admin: number
  personal: number
  contact: number
}

const useDispatchUpdate = (
  key: keyof State,
  dispatch: (state: Partial<State>) => void,
) =>
  useCallback(
    (value: number) => {
      dispatch({
        [key]: value,
      })
    },
    [key, dispatch],
  )

const DelegationCalculator = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const onRequestClose = useCallback(() => {
    setIsModalOpen(false)
  }, [setIsModalOpen])
  const onOpenModal = useCallback(() => {
    setIsModalOpen(true)
  }, [setIsModalOpen])

  const [assistantLevel, setAssistantLevel] = useState<AssistantLevel>(
    AssistantLevel.NEVER,
  )
  const [whatINeed, setWhatINeed] = useState<WhatINeed>(WhatINeed.CURIOUS)

  const [state, dispatch] = useReducer(
    (state: State, action: Partial<State>) => ({
      ...state,
      ...action,
    }),
    INITIAL_STATE,
  )

  const onSchedulingChange = useDispatchUpdate('scheduling', dispatch)
  const onInboxChange = useDispatchUpdate('inbox', dispatch)
  const onAdminChange = useDispatchUpdate('admin', dispatch)
  const onContactChange = useDispatchUpdate('contact', dispatch)
  const onPersonalChange = useDispatchUpdate('personal', dispatch)

  const formRef = useRef<HTMLFormElement>(null)
  const secondSection = useRef<HTMLDivElement>(null)
  const resultSection = useRef<HTMLDivElement>(null)

  const onWhoAmIChange = useCallback(
    (value: string) => {
      let kind = value as ArchetypeKind
      let archetype = ARCHETYPES[kind]

      if (!archetype) {
        kind = ArchetypeKind.EARLY_STAGE
        archetype = ARCHETYPES[kind]
      }

      dispatch({
        ...INITIAL_STATE,
        archetype: kind,
        ...archetype.hours,
      })

      setTimeout(() => {
        const el = secondSection.current || null

        if (el) {
          window.scroll({
            top: el.offsetTop,
            behavior: 'smooth',
          })
        }
      }, 500)
    },
    [dispatch],
  )

  const onSubmit = useCallback(async (e) => {
    e.preventDefault()
    await navigate(NEW_SIGNUP_PATH, {
      state: {
        delegationCalculator: getFormValues(e.target),
      },
    })
  }, [])

  const onDoMath = useCallback(() => {
    const el = resultSection.current || null

    if (el) {
      window.scroll({
        top: el.offsetTop,
        behavior: 'smooth',
      })
    }

    // Track event
    // https://double.height.app/T-1829
    if (formRef.current) {
      const values = getFormValues(formRef.current)
      trackEvent('calculator', {
        event: 'calculator',
        who_are_you: values.who_am_i, // eslint-disable-line camelcase
        delegation_approach_1: values.assistant_level, // eslint-disable-line camelcase
        delegation_approach_2: values.what_i_need, // eslint-disable-line camelcase
        time_scheduling: values.hours_spend_scheduling, // eslint-disable-line camelcase
        time_inbox: values.hours_spend_inbox, // eslint-disable-line camelcase
        time_admin: values.hours_spend_admin, // eslint-disable-line camelcase
        time_contacts: values.hours_spend_contact, // eslint-disable-line camelcase
        time_personal: values.hours_spend_personal, // eslint-disable-line camelcase
      })
    }
  }, [])

  const archetype =
    ARCHETYPES[state.archetype] || ARCHETYPES[ArchetypeKind.EARLY_STAGE]
  const sum = Math.round(
    HOURS_KEYS.reduce(
      (acc, key) =>
        // @ts-ignore
        acc + state[key] * (HOURS_MULTIPLICATORS[key] || 1),
      0,
    ),
  )
  const hoursByMonth = sum * 4
  const hoursByYear = (hoursByMonth * 12) / 8

  return (
    <div>
      <form ref={formRef} onSubmit={onSubmit}>
        <Outer column paddingBottom>
          <Inner>
            <SectionHeader>{'Who are you?'}</SectionHeader>
            <RadioChoice
              name={'who_am_i'}
              options={OPTIONS}
              value={state.archetype}
              onChange={onWhoAmIChange}
            />
          </Inner>
          <Inner ref={secondSection}>
            <SectionHeader>{"What's your delegation approach?"}</SectionHeader>
            <SelectInput
              placeholder={''}
              name={'assistant_level'}
              // eslint-disable-next-line
              options={Object.entries(AssistantLevel).map(([value, name]) => ({
                name,
                value,
              }))}
              defaultValue={assistantLevel}
              onChangeValue={(value) =>
                setAssistantLevel(value as AssistantLevel)
              }
            />
            <And>{'And'}</And>
            <SelectInput
              placeholder={''}
              name={'what_i_need'}
              // eslint-disable-next-line
              options={Object.entries(WhatINeed).map(([value, name]) => ({
                name,
                value,
              }))}
              defaultValue={whatINeed}
              onChangeValue={(value) => setWhatINeed(value as WhatINeed)}
            />
          </Inner>
          <Inner>
            <SectionHeader>
              {
                'How much time do you spend on a typical week, on easy-to-delegate tasks?'
              }
            </SectionHeader>
            <SectionSubtitle>
              {"We're showing you how other "}
              <Archetype as={'strong'}>{archetype.plurial}</Archetype>
              {' do on average.'}
            </SectionSubtitle>
            <SliderBox
              name={'hours_spend_scheduling'}
              label={'📆  Scheduling'}
              value={state.scheduling}
              description={
                'Booking meetings, confirming, rescheduling, following up, noting next steps, protecting personal time...'
              }
              onChange={onSchedulingChange}
            />
            <SliderBox
              name={'hours_spend_inbox'}
              label={'📥  Inbox management'}
              value={state.inbox}
              description={
                'Daily triage, email drafting, following up, archiving, adding people to CRM...'
              }
              onChange={onInboxChange}
            />
            <SliderBox
              name={'hours_spend_admin'}
              label={'💳  Admin'}
              value={state.admin}
              description={
                'Expense reports, requesting & paying invoices, collecting signatures, managing bills, reconciling statements...'
              }
              onChange={onAdminChange}
            />
            <SliderBox
              name={'hours_spend_contact'}
              label={'☎️  Contact management'}
              value={state.contact}
              description={
                'CRM, keeping up with connections, following up, staying on top of network updates...'
              }
              onChange={onContactChange}
            />
            <SliderBox
              name={'hours_spend_personal'}
              label={'🌴  Personal tasks'}
              value={state.personal}
              description={
                'Travel booking, event planning, shopping, gifts, research...'
              }
              onChange={onPersonalChange}
            />
          </Inner>
        </Outer>
        <Outer column paddingBottom>
          <CenteredSubheadRegular>
            {'See how much time delegation could save you'}
          </CenteredSubheadRegular>
          <ContinueButton
            type={'button'}
            title={'Do the math'}
            isValid
            onClick={onDoMath}
          />
        </Outer>
        <ResultSection ref={resultSection}>
          <Outer column>
            <Computed>
              <CenteredHeading2>
                {'By delegating, you can get '}
                <strong>{sum}</strong>
                {' hours back each week!'}
              </CenteredHeading2>
              <CenteredHeading2>
                {'That’s '}
                <strong>{hoursByMonth}</strong>
                {' hours each month,'}
                <br />
                {' and '}
                <strong>{hoursByYear}</strong>
                {' work days in a year'}
              </CenteredHeading2>
              <Centered>
                <ContinueButton type={'submit'} title={'Get Started'} isValid />
              </Centered>
              <Centered>
                <ModalButton
                  type={'button'}
                  as={'button'}
                  onClick={onOpenModal}
                >
                  {'How did we calculate this?'}
                </ModalButton>
              </Centered>
            </Computed>
            <ListTitle>{`${capitalize(
              archetype.plurial,
            )} who use Double have more time to...`}</ListTitle>
            {archetype.lines.map((line, index) => (
              <ListItem key={index}>{line}</ListItem>
            ))}
          </Outer>
        </ResultSection>
        <Section>
          <Outer column>
            <CenteredHeading2>
              {'Successful CEOs get more done with Double'}
            </CenteredHeading2>
            <CenteredSubheadRegular>
              {'See why our customers love working with their assistants'}
            </CenteredSubheadRegular>
          </Outer>
          <TestimonyList items={archetype.testimonies} />
        </Section>
        <Outer column paddingBottom>
          <CenteredSubheadRegular>
            {'Ready to take back your day?'}
          </CenteredSubheadRegular>
          <ContinueButton type={'submit'} title={'Get Started'} isValid />
        </Outer>
      </form>
      {isModalOpen && <Modal onRequestClose={onRequestClose} />}
    </div>
  )
}

export default DelegationCalculator
