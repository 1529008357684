import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import type { TagsImageListFragment } from '../../../graphql-types'
import { MarkdownRenderer } from '../Markdown'
import _TextImage from '../TextImage'
import { mobile } from '../mixins'
import { Color } from '../theme'
import { subheadMediumStyle, calloutRegularStyle } from '../typography'

const MainTags = styled.ul`
  margin-top: 40px;
  ${mobile(css`
    margin-top: 24px;
  `)}
`

const MainTag = styled.li<{ isSelected: boolean }>`
  cursor: default;
  ${subheadMediumStyle};
  color: ${Color.GRAY_6};
  border: 1px solid ${Color.GRAY_2};
  border-radius: 12px;
  padding: 10px 20px !important;
  margin: 0 8px 8px 0 !important;
  display: inline-block;

  ${({ isSelected }) =>
    isSelected
      ? css`
          background-color: ${Color.PURPLE_1};
          border-color: ${Color.PURPLE_1};
          color: ${Color.PURPLE_5};
        `
      : css`
          &:hover {
            border-color: ${Color.PURPLE_1};
            color: ${Color.PURPLE_5};
          }
        `}
`

const SubTags = styled.ul<{ isSelected: boolean }>`
  ${({ isSelected }) => !isSelected && 'display: none;'}
  margin-top: 32px;
  text-align: left;
  ${mobile(css`
    display: none;
  `)}
`

const SubTag = styled.li`
  * {
    ${calloutRegularStyle};
  }

  a:hover {
    color: ${Color.PURPLE_5};
  }

  background: ${Color.GRAY_1};
  border-radius: 12px;
  padding: 6px 16px;
  margin: 0 8px 8px 0;
  display: inline-block;
  cursor: default;
`

type Props = TagsImageListFragment

const TagsImage = ({ title, tags }: Props) => {
  const [selectedTagIndex, setSelectedTagIndex] = useState(0)

  const selectedTag = tags?.[selectedTagIndex]

  return (
    <_TextImage
      title={title}
      image={selectedTag?.image}
      imageStyle={{
        borderRadius: 12,
        boxShadow: 'inset 0px -2px 2px rgba(0, 0, 0, 0.05)',
        filter:
          'drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.1))',
      }}
      imageCaption={tags?.map(
        (tag, index) =>
          !!tag && (
            <SubTags key={index} isSelected={selectedTagIndex === index}>
              {tag.tags?.tags?.split(/[\n\r]+/g).map(
                (markdown, index) =>
                  !!markdown.trim() && (
                    <SubTag key={index}>
                      <MarkdownRenderer>{markdown.trim()}</MarkdownRenderer>
                    </SubTag>
                  ),
              )}
            </SubTags>
          ),
      )}
    >
      <MainTags>
        {tags?.map(
          (tag, index) =>
            !!tag && (
              <MainTag
                key={index}
                isSelected={selectedTagIndex === index}
                noColor
                onClick={() => setSelectedTagIndex(index)}
              >
                {tag.title}
              </MainTag>
            ),
        )}
      </MainTags>
    </_TextImage>
  )
}

export default TagsImage
