import React from 'react'
import styled, { css } from 'styled-components'

import type { TimelineItemFragment } from '../../../graphql-types'
import { disc, tablet, mobile } from '../mixins'
import { Color } from '../theme'
import { Heading2, BodyRegular } from '../typography'

import Img from './Img'

const Outer = styled.div<{ reversed?: boolean; lineUp?: boolean }>`
  display: flex;
  align-items: center;

  ${({ reversed }) =>
    !!reversed &&
    css`
      flex-direction: row-reverse;
    `}

  ${({ lineUp }) =>
    !!lineUp &&
    css`
      &:before {
        content: '';
        flex: 0 0 0;
        box-shadow: 0 0 0 2px ${Color.GRAY_2};
        order: 2;
        margin-top: -100px;
        margin-bottom: 100px;
        z-index: 2;
        align-self: stretch;
        ${tablet(css`
          content: none;
        `)}
      }

      ${tablet(css`
        margin-top: 72px;
      `)}
    `}

  & > img {
    border-${({ reversed }) => (reversed ? 'right' : 'left')}-width: 4px;
    transform: translateX(${({ reversed }) => (reversed ? 2 : -2)}px);
  }

  ${tablet(css`
    flex-direction: column;
  `)}
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  order: 1;
  flex: 1;
  padding: 60px;

  ${tablet(css`
    padding: 0 0 40px;
  `)}
`

const Top = styled.div`
  display: flex;
  align-items: center;

  ${tablet(css`
    flex-direction: column;
    align-items: flex-start;
  `)}
`

const Image = styled(Img)`
  flex: 0 0 auto;
  margin: 8px 0;
  width: 50%;
  order: 3;
  border: solid 0 ${Color.GRAY_2};

  ${tablet(css`
    border: none;
    width: 100%;
    margin: 0;
  `)}
`

const Number = styled.div`
  color: ${Color.WHITE};
  background-color: ${Color.PURPLE_5};
  ${disc(56)}
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4px;
  padding-left: 1px;
  margin-right: 24px;
  flex: 0 0 auto;

  ${tablet(css`
    margin-bottom: 24px;
  `)}

  ${mobile(css`
    ${disc(40)}
    margin-bottom: 16px;
  `)}
`

const Title = styled(Heading2)``

const Text = styled(BodyRegular)`
  margin-top: 16px;
  margin-left: 80px;

  ${tablet(css`
    margin-top: 16px;
    margin-left: 0;
  `)}
`

type Props = TimelineItemFragment

const TimelineItem = ({ title, text, number, image, layout }: Props) => (
  <Outer reversed={!layout} lineUp={!!number && number > 1}>
    <Inner>
      <Top>
        <Number>
          <Heading2 noColor>{number}</Heading2>
        </Number>
        <Title>{title}</Title>
      </Top>
      {!!text?.text && <Text>{text.text}</Text>}
    </Inner>
    {!!image && (
      <Image
        asset={image}
        // maxWidth={600}
      />
    )}
  </Outer>
)

export default TimelineItem
