import React from 'react'
import styled, { css } from 'styled-components'

import type {
  IconsListFragment,
  IconItemFragment,
} from '../../../graphql-types'
import { mobile } from '../mixins'
import { BodySemibold, BodyRegular } from '../typography'

import Img from './Img'

const ItemOuter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 150px;
  align-items: center;
  padding: 0 24px;
  margin: 0 12px;
  text-align: center;
  max-width: 360px;

  ${mobile(css`
    & + & {
      margin-top: 40px;
    }
  `)}
`

const Title = styled(BodySemibold)`
  margin-bottom: 16px;
`
const Text = styled(BodyRegular)``
const Icon = styled(Img)`
  height: 48px;
  width: 48px;
  margin-bottom: 32px;

  ${mobile(css`
    margin-bottom: 16px;
  `)}
`

type ItemProps = IconItemFragment

const Item = ({ icon, title, text }: ItemProps) => (
  <ItemOuter>
    <Icon asset={icon} />
    <Title>{title}</Title>
    <Text>{text?.text}</Text>
  </ItemOuter>
)

const ListOuter = styled.div`
  display: flex;

  ${mobile(css`
    flex-direction: column;
    align-items: center;
  `)}
`

type Props = IconsListFragment

const IconsList = ({ icons }: Props) => (
  <ListOuter>
    {icons?.map((icon, index) => !!icon && <Item key={index} {...icon} />)}
  </ListOuter>
)

export default IconsList
