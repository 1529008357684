import styled, { css } from 'styled-components'

import {
  RadioInput as _RadioInput,
  Button,
  SelectInput as _SelectInput,
} from '../../../forms'
import { Container } from '../../../layout'
import { mobile } from '../../../mixins'
import { Color } from '../../../theme'
import {
  Heading3,
  BodySemibold,
  BodyRegular,
  CalloutRegular,
  CalloutSemibold,
  SubheadRegular,
  Heading2,
} from '../../../typography'

import RadioOff from './RadioOff.svg'
import RadioOn from './RadioOn.svg'

export const RadioLabel = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${Color.GRAY_2};
  border-radius: 16px;
  height: 77px;
  padding: 0 32px 0 72px;
  cursor: pointer;
  margin: 0 0 16px 0;

  background: transparent no-repeat url(${RadioOff}) 32px center;
`

export const RadioInput = styled(_RadioInput)`
  & > input:checked ~ ${RadioLabel} {
    border: 1px solid ${Color.PURPLE_4};
    background: transparent no-repeat url(${RadioOn}) 32px center;
  }
`

export const SectionHeader = styled(BodySemibold)<{ required?: boolean }>`
  padding: 22px 0 0 !important;
  margin: 16px 0 16px 0 !important;

  ${mobile(css`
    font-size: 15px;
    line-height: 18px;
    margin-left: 0;
    margin-right: 0;
  `)}

  ${({ required }) =>
    !!required &&
    css`
      &:after {
        content: ' *';
        color: ${Color.FLAMINGO_6};
      }
    `}
`

export const SectionSubtitle = styled(CalloutRegular)`
  margin-bottom: 16px;
`

export const ModalButton = styled(CalloutRegular)`
  color: ${Color.PURPLE_5};
  background: none;
  border: none;
  display: inline;
  box-shadow: none;
`

export const ListTitle = styled(Heading3)`
  margin-bottom: 16px;
`

export const ListItem = styled(BodyRegular)``

export const Inner = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  margin: 34px auto 0 auto;
  width: 528px;
  ${mobile(css`
    max-width: 327px;
    margin: 0 0 0 0;
  `)}
`

export const Outer = styled(Container)`
  flex-wrap: wrap;
  max-width: 720px;

  ${mobile(css`
    max-width: 327px;
  `)}
`

export const Section = styled.div`
  padding: 128px 0;
`

export const ResultSection = styled(Section)`
  background: ${Color.PURPLE_1};
`

export const ContinueButton = styled(Button)`
  margin: 8px 0;
  margin-top: 16px;
  width: 217px;
  margin-left: auto;
  margin-right: auto;

  &:focus {
    background-color: ${Color.PURPLE_6};
  }

  ${mobile(css`
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 217px;
  `)}
`

export const Computed = styled.div`
  margin-bottom: 64px;
`

export const Archetype = styled(CalloutSemibold)`
  color: ${Color.PURPLE_4} !important;
`

export const And = styled(BodyRegular)`
  margin: 16px 0 8px 0 !important;
`

export const SelectInput = styled(_SelectInput)`
  margin: 8px 0 0 0;
  transition: box-shadow 200ms ease;

  &:focus {
    box-shadow: inset 0 0 0 2px ${Color.PURPLE_4};
  }

  &.error:not(:focus) {
    box-shadow: inset 0 0 0 2px #e6635c;
  }

  ${mobile(css`
    margin-left: 0;
    margin-right: 0;
  `)}
`

const centeredStyle = css`
  text-align: center;
  margin-bottom: 24px !important;

  strong {
    color: ${Color.PURPLE_4};
  }
`

export const Centered = styled.div`
  ${centeredStyle}
`

export const CenteredSubheadRegular = styled(SubheadRegular)`
  ${centeredStyle}
`

export const CenteredHeading2 = styled(Heading2)`
  ${centeredStyle}
`
