import type { ComponentProps } from 'react'
import React from 'react'
import styled, { css } from 'styled-components'

import type {
  ReviewItemFragment,
  ReviewsListFragment,
} from '../../../graphql-types'
import { maxScreenWidth } from '../mixins'
import { Color } from '../theme'
import { Heading3, CalloutRegular, CalloutSemibold } from '../typography'

const ItemOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 24px;
  margin: 0 12px;
  box-shadow: 0px 12px 32px rgba(98, 104, 111, 0.2);
  border-radius: 32px;
  flex: 1 1 21%;

  ${maxScreenWidth(1020)(css`
    flex-basis: 40%;
    :nth-child(n + 3) {
      margin-top: 24px;
    }
  `)}

  ${maxScreenWidth(580)(css`
    flex-basis: 90%;
    :nth-child(n + 2) {
      margin-top: 24px;
    }
  `)}
`

const Spacer = styled.div`
  flex: 1 1 0;
`

const Title = styled(Heading3)`
  margin-bottom: 8px;
`

const Rating = styled(CalloutSemibold)`
  color: ${Color.PURPLE_4};
  margin: 24px 0 4px;
`

const ReviewerTitle = styled(CalloutSemibold)`
  color: ${Color.GRAY_6};
`
const ReviewerSubtitle = styled(CalloutRegular)`
  color: ${Color.GRAY_6};
`

type ItemProps = ReviewItemFragment & ComponentProps<typeof ItemOuter>

const ReviewItem = ({
  title,
  text,
  reviewerTitle,
  reviewerSubtitle,
}: ItemProps) => (
  <ItemOuter>
    <Title noResponsive>{`“${title}`}</Title>
    <CalloutRegular noResponsive>{text?.text}</CalloutRegular>
    <Spacer />
    <Rating>{'5.0 ★★★★★'}</Rating>
    <ReviewerTitle>{reviewerTitle}</ReviewerTitle>
    <ReviewerSubtitle>{reviewerSubtitle}</ReviewerSubtitle>
  </ItemOuter>
)

const Outer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

type Props = ReviewsListFragment

const ReviewsList = ({ items }: Props) => (
  <Outer>
    {items?.map(
      (item, index) => !!item && <ReviewItem key={index} {...item} />,
    )}
  </Outer>
)

export default ReviewsList
