import styled, { css } from 'styled-components'

import { mobile, tablet } from '../../mixins'
import { Color } from '../../theme'
import _Container from '../Container'
import Img from '../Img'

export const Container = styled(_Container)`
  overflow: hidden;
`

type ImgProps = {
  height?: number
  tabletHeight?: number
  mobileHeight?: number
  theme?: string
}

export const Image = styled(Img)<ImgProps>`
  ${({ height, theme, tabletHeight, mobileHeight }: ImgProps) =>
    css`
      ${height ? `height: ${height}px;` : ''}
      ${theme === 'rounded' ? 'border-radius: 24px;' : ''}
      ${tabletHeight
        ? tablet(
            css`
              height: ${tabletHeight}px;
            `,
          )
        : ''}
      ${mobileHeight
        ? mobile(
            css`
              height: ${mobileHeight}px;
            `,
          )
        : ''}
    `}
`

export const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ImgBreadcrumbsContainer = styled.div`
  margin-top: 16px;
  display: none;
  justify-content: center;
  gap: 10px;
  max-width: 250px;

  ${mobile(css`
    display: flex;
  `)}

  ${tablet(css`
    display: flex;
  `)}
`

export const Breadcrumb = styled.div<{ active?: boolean }>`
  width: 24px;
  height: 3px;
  border-radius: 2px;
  background-color: ${(props) =>
    props.active ? `${Color.PURPLE_5}` : `${Color.GRAY_2}`};
`
