import React from 'react'
import styled, { css } from 'styled-components'

import type {
  VignettesListFragment,
  VignetteItemFragment,
} from '../../../graphql-types'
import { mobile, tablet } from '../mixins'
import { BodyRegular, Heading2, Heading3 } from '../typography'

import Img from './Img'
import Link from './Link'

const ItemOuter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 ${(270 / 1200) * 100}%;
  max-width: 270px;
  min-width: 180px;
  align-items: center;
  text-align: center;
  margin-bottom: 52px;

  ${mobile(css`
    margin-bottom: 32px;
    flex-basis: 270px;
  `)}
`

const Title = styled(Heading3)`
  margin-bottom: 10px;
`
const Text = styled(BodyRegular)``
const Image = styled(Img)`
  width: 100%;
  margin-bottom: 32px;
  border-radius: 20px;

  ${mobile(css`
    margin-bottom: 20px;
  `)}
`

type ItemProps = VignetteItemFragment

const Item = ({ image, title, text }: ItemProps) => (
  <ItemOuter>
    <Image asset={image} />
    <Title>{title}</Title>
    <Text>{text}</Text>
  </ItemOuter>
)

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Heading = styled(Heading2)`
  margin-bottom: 40px;

  ${mobile(css`
    margin-bottom: 32px;
  `)}
`

const ListOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  ${tablet(css`
    justify-content: space-around;
  `)}

  ${mobile(css`
    flex-direction: column;
    align-items: center;
  `)}
`

type Props = VignettesListFragment

const VignettesList = ({ title, cta, vignettes }: Props) => (
  <Outer>
    {!!title && <Heading noResponsive>{title}</Heading>}
    <ListOuter>
      {vignettes?.map(
        (vignette, index) => !!vignette && <Item key={index} {...vignette} />,
      )}
    </ListOuter>
    {!!cta && <Link {...cta} />}
  </Outer>
)

export default VignettesList
