import React from 'react'
import styled, { css } from 'styled-components'

import type { StatisticsFragment } from '../../../graphql-types'
import { tablet, mobile } from '../mixins'
import { Color } from '../theme'
import { Heading2, heading1Style, BodyRegular, BodyMedium } from '../typography'

const Outer = styled.div`
  border: 1px solid ${Color.GRAY_2};
  display: flex;
  flex-direction: row;
  border-radius: 12px;

  ${mobile(css`
    flex-direction: column;
    border: none;
  `)}
`

const Stats = styled.div`
  flex: 1 1 58%;
  padding: ${(100 / 1200) * 100}%;

  ${tablet(css`
    padding: ${(70 / 1200) * 100}%;
  `)}

  ${mobile(css`
    padding: 0;
  `)}
`

const Bars = styled.div`
  flex: 1 1 42%;
  border-left: 1px solid ${Color.GRAY_2};
  padding: 45px 7% 38px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${tablet(css`
    padding: 45px ${(70 / 1200) * 100}% 38px;
  `)}

  ${mobile(css`
    border: 1px solid ${Color.GRAY_2};
    border-radius: 12px;
    margin-top: 24px;
    padding: 24px 24px 38px;
  `)}
`

const Title = styled(Heading2)``

const Stat = styled.div`
  margin-top: 32px;
  display: flex;
  ${tablet(css`
    flex-direction: column;
    margin-top: 24px;
  `)}
`

const StatNumber = styled.span`
  ${heading1Style};
  color: ${Color.PURPLE_5};
  margin-right: 24px;
  flex: 1 0 140px;
  ${tablet(css`
    flex-basis: auto;
  `)}
`

const StatLegend = styled(BodyRegular)`
  font-size: 20px;
  font-weight: 400;
  ${mobile(css`
    font-size: 18px;
  `)}
`

const BarsTitle = styled(BodyMedium)`
  font-size: 20px;
  color: ${Color.GRAY_6};
  text-align: center;
  flex: 1 0 100%;
  margin-bottom: 24px;

  ${tablet(css`
    font-size: 18px;
    margin-bottom: 32px;
  `)}
`

const BarOuter = styled.div`
  flex: 0 0 49%;
  display: flex;
  flex-direction: column;
`

const BarAbstract = styled.div<{ heightRatio: number }>`
  ${({ heightRatio }) => `height: ${260 * heightRatio}px;`}
`

const Bar = styled(BarAbstract)<{ isPrimary: boolean; heightRatio: number }>`
  border-radius: 12px;
  background-color: ${Color.FLAMINGO_2};
  margin-bottom: 24px;

  ${({ isPrimary }) =>
    isPrimary &&
    css`
      background-color: ${Color.PURPLE_5};
      box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1),
        0px 1px 4px rgba(0, 0, 0, 0.1), inset 0px -2px 2px rgba(0, 0, 0, 0.05);
    `}

  ${tablet(css`
    margin-bottom: 20px;
  `)}
`

const BarGhost = styled(BarAbstract)``

const BarLegend = styled(BodyMedium)`
  font-size: 20px;
  text-align: center;

  ${tablet(css`
    font-size: 18px;
  `)}
`

const extractNumber = (text: string | undefined | null): number =>
  parseFloat((text || '0').replace(/[^\d.]/g, ''))

type Props = StatisticsFragment

const Statistics = ({
  title,
  barsTitle,
  stat1Number,
  stat1Legend,
  stat2Number,
  stat2Legend,
  bar1Number,
  bar1Legend,
  bar1IsPrimary,
  bar2Number,
  bar2Legend,
  bar2IsPrimary,
}: Props) => (
  <Outer>
    <Stats>
      <Title noResponsive>{title}</Title>
      <Stat>
        <StatNumber noColor noResponsive>
          {stat1Number}
        </StatNumber>
        <StatLegend>{stat1Legend}</StatLegend>
      </Stat>
      <Stat>
        <StatNumber noColor noResponsive>
          {stat2Number}
        </StatNumber>
        <StatLegend>{stat2Legend}</StatLegend>
      </Stat>
    </Stats>
    <Bars>
      <BarsTitle>{barsTitle}</BarsTitle>
      <BarOuter>
        <BarGhost
          heightRatio={
            1 -
            extractNumber(bar1Number) /
              Math.max(extractNumber(bar1Number), extractNumber(bar2Number))
          }
        />
        <Bar
          isPrimary={!!bar1IsPrimary}
          heightRatio={
            extractNumber(bar1Number) /
            Math.max(extractNumber(bar1Number), extractNumber(bar2Number))
          }
        />
        <BarLegend>
          {bar1Legend}
          <br />
          <strong>{bar1Number}</strong>
        </BarLegend>
      </BarOuter>
      <BarOuter>
        <BarGhost
          heightRatio={
            1 -
            extractNumber(bar2Number) /
              Math.max(extractNumber(bar1Number), extractNumber(bar2Number))
          }
        />
        <Bar
          isPrimary={!!bar2IsPrimary}
          heightRatio={
            extractNumber(bar2Number) /
            Math.max(extractNumber(bar1Number), extractNumber(bar2Number))
          }
        />
        <BarLegend>
          {bar2Legend}
          <br />
          <strong>{bar2Number}</strong>
        </BarLegend>
      </BarOuter>
    </Bars>
  </Outer>
)

export default Statistics
