export const COMPARE = {
  headers: ['Full-time assistant', 'Temp assistant', 'Double assistant'],
  rows: [
    {
      name: 'Sourcing effort',
      data: ['High', 'Medium', 'Low'],
    },
    {
      name: 'Time to onboard',
      data: ['2 weeks', '1 week', '1 day'],
    },
    {
      name: 'Internal resources required',
      data: [
        'Recruiting, HR, payroll, IT, training, ongoing management',
        'Payroll, IT, training, ongoing management',
        'None',
      ],
    },
    {
      name: 'Software proficiency',
      data: [
        'Varies',
        'Varies',
        'Experienced with top tools used by tech companies',
      ],
    },
    {
      name: 'Location',
      data: [
        'In-office',
        'Remote, often overseas',
        'Remote, local to your area',
      ],
    },
    {
      name: 'Communication',
      data: [
        'Dispersed - Email, Slack, Teams, Skype, Text',
        'Dispersed - Email, Slack, Teams, Skype',
        'Double app - chat, text, voice notes; Gmail, Slack',
      ],
    },
    {
      name: 'Delegation process',
      data: ['Manual', 'Manual', 'Template-based'],
    },
    {
      name: 'Cost per week (15 hr avg)',
      data: [
        '$1,480 payroll + job listing, employment taxes, and benefits',
        '$840 bill rate (includes taxes and fees)',
        '$650 (no employment taxes or fees)',
      ],
      links: [null, null, { label: 'Pricing plans', path: '/pricing' }],
    },
    {
      name: 'Commitment',
      data: ['At-will employment', 'Contract terms', 'No commitment'],
    },
  ],
}
