import { Link as NavLink } from 'gatsby'
import type { ComponentProps } from 'react'
import React from 'react'
import styled, { css } from 'styled-components'

import { mobile, tablet } from '../../mixins'
import { Color } from '../../theme'
import {
  CaptionRegular,
  Heading3,
  CalloutRegular,
  CalloutSemibold,
} from '../../typography'
import _Container from '../Container'

import { COMPARE } from './strings'

const onSmallScreen = tablet

const Container = styled(_Container)`
  ${onSmallScreen(css`
    padding-left: 12px;
    padding-right: 12px;
  `)}

  ${mobile(css`
    padding-left: 0px;
    padding-right: 0px;
  `)}
`

const Outer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const Table = styled.div`
  width: 100%;
`

const TableRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 16px;
  transition: background-color 200ms ease;

  &:hover {
    background-color: ${Color.GRAY_1};
  }

  ${mobile(css`
    border-radius: 0;
  `)}
`

const TableHeadersRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  &:before {
    content: '';
    background-color: ${Color.GRAY_1};
    position: absolute;
    width: 200vw;
    height: 2px;
    bottom: 32px;
    left: -100vw;
    ${mobile(css`
      bottom: 22px;
    `)}
  }
`

const TableColumnHeader = styled(Heading3)`
  ${({ noColor }) =>
    noColor &&
    css`
      color: ${Color.GRAY_6};
    `}

  padding: 18px 20px 50px;
  flex: 0 0 25%;

  ${onSmallScreen(css`
    flex-basis: 33%;
    padding: 12px 12px 40px;
  `)}

  ${mobile(css`
    padding: 8px 8px 40px;
  `)}
`

const TableRowHeader = styled(CalloutSemibold)<{ hideOnSmallScreen?: boolean }>`
  padding: 20px;
  flex: 0 0 25%;

  ${({ hideOnSmallScreen }) =>
    onSmallScreen(css`
      flex-basis: 100%;
      padding-bottom: 0;
      padding: 12px;
      ${!!hideOnSmallScreen &&
      css`
        display: none;
      `}
    `)}

  ${mobile(css`
    padding: 8px;
  `)}
`

const TableCell = styled(CalloutRegular)`
  padding: 20px;
  flex: 0 0 25%;

  a {
    color: ${Color.PURPLE_5};
  }

  ${onSmallScreen(css`
    flex-basis: 33%;
    padding: 12px;
  `)}

  ${mobile(css`
    padding: 8px;
  `)}
`

const Caption = styled(CaptionRegular)`
  color: ${Color.GRAY_5};
  margin-top: 32px;
`

type Props = ComponentProps<typeof Outer>

const CompareTable = (props: Props) => (
  <Container noPaddingTablet>
    <Outer {...props}>
      <Table>
        <TableHeadersRow>
          <TableRowHeader hideOnSmallScreen />
          {COMPARE.headers.map((title, index) => (
            <TableColumnHeader key={title} noColor={index !== 2}>
              {title}
            </TableColumnHeader>
          ))}
        </TableHeadersRow>
        {COMPARE.rows.map(({ name, data, links }) => (
          <TableRow key={name}>
            <TableRowHeader>{name}</TableRowHeader>
            {data.map((text, index) => (
              <TableCell key={text}>
                {text}
                {!!(links && links[index]) && (
                  <>
                    {' - '}
                    <NavLink to={links[index]?.path || ''}>
                      {links[index]?.label}
                    </NavLink>
                  </>
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </Table>
      <Caption>{'Sources: Bureau of Labor Statistics, May 2019 '}</Caption>
    </Outer>
  </Container>
)

export default CompareTable
