import styled from 'styled-components'

import { Color } from '../theme'
import { BodySemibold, BodyMedium } from '../typography'

export const SliderDescription = styled.div`
  padding: 12px 20px;
  background: ${Color.GRAY_1};
  color: ${Color.GRAY_6};
`

export const LightDescription = styled.div`
  color: ${Color.GRAY_6};
`

export const SliderValue = styled.div`
  padding: 0 20px 0 20px;
`

export const SliderHours = styled(BodySemibold)`
  color: ${Color.PURPLE_4};
`

export const SliderLabel = styled(BodyMedium)`
  flex: 1;
`

export const SliderHead = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px 20px 0 20px;
`

export const SliderOuter = styled.div`
  input[type='range'] {
    width: 100%;
    margin: 16px 0 24px 0;
    background-color: transparent;
    -webkit-appearance: none;
  }
  input[type='range']:focus {
    outline: none;
  }
  input[type='range']::-webkit-slider-runnable-track {
    background: ${Color.GRAY_1};
    border: none;
    border-radius: 20px;
    width: 100%;
    height: 8px;
    cursor: pointer;
  }
  input[type='range']::-webkit-slider-thumb {
    margin-top: -7.5px;
    width: 24px;
    height: 24px;
    background: #ffffff;
    border: none;
    border-radius: 12px;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1),
      inset 0px -2px 2px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    -webkit-appearance: none;
  }
  input[type='range']:focus::-webkit-slider-runnable-track {
    background: ${Color.GRAY_1};
  }
  input[type='range']::-moz-range-track {
    background: ${Color.GRAY_1};
    border: none;
    border-radius: 20px;
    width: 100%;
    height: 8px;
    cursor: pointer;
  }
  input[type='range']::-moz-range-progress {
    border: none;
    border-radius: 20px;
    width: 100%;
    height: 8px;
    background: ${Color.PURPLE_4};
  }
  input[type='range']::-moz-range-thumb {
    width: 24px;
    height: 24px;
    background: #ffffff;
    border: none;
    border-radius: 24px;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1),
      inset 0px -2px 2px rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
  input[type='range']::-ms-track {
    background: transparent;
    border: none;
    color: transparent;
    width: 100%;
    height: 8px;
    border-radius: 20px;
    cursor: pointer;
  }
  input[type='range']::-ms-fill-lower {
    background: ${Color.PURPLE_4};
    border: none;
    border-radius: 20px;
  }
  input[type='range']::-ms-fill-upper {
    background: ${Color.GRAY_1};
    border: none;
    border-radius: 20px;
  }
  input[type='range']::-ms-thumb {
    width: 24px;
    height: 24px;
    background: #ffffff;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
  }
  input[type='range']:focus::-ms-fill-lower {
    background: ${Color.PURPLE_4};
  }
  input[type='range']:focus::-ms-fill-upper {
    background: ${Color.GRAY_1};
  }
  /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
  @supports (-ms-ime-align: auto) {
    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
    input[type='range'] {
      margin: 0;
      /*Edge starts the margin from the thumb, not the track as other browsers do*/
    }
  }
`

export const BoxedOuter = styled(SliderOuter)`
  border: 1px solid #ecedf0;
  box-sizing: border-box;
  border-radius: 16px;
  overflow: hidden;
  margin: 0 0 16px 0;
`
