import React from 'react'
import styled, { css } from 'styled-components'

import type { TestimonialFragment } from '../../../graphql-types'
import { disc, mobile } from '../mixins'
import { Color } from '../theme'
import { Heading2, BodySemibold, CalloutRegular } from '../typography'

import Img from './Img'

const Outer = styled.div`
  text-align: center;
  max-width: 720px;
  margin: 0 auto;
`

const Avatar = styled(Img)`
  ${disc(64)}
  box-shadow: 0 0 0 5px ${Color.WHITE};

  & + & {
    margin-left: -11px;
  }

  ${mobile(css`
    ${disc(48)}
    & + & {
      margin-left: -6px;
    }
  `)}
`

const Quote = styled(Heading2)`
  margin: 40px 0 !important;
`

type Props = TestimonialFragment

const Testimonial = ({ quote, author, partner }: Props) => (
  <Outer>
    <Avatar asset={author?.avatar} />
    <Avatar asset={partner?.avatar} />
    <Quote>{`“${quote?.quote}”`}</Quote>
    <BodySemibold>{author?.name}</BodySemibold>
    <CalloutRegular noResponsive>
      {[author?.position, author?.company].filter(Boolean).join(' at ')}
    </CalloutRegular>
  </Outer>
)

export default Testimonial
