import type { ReactNode } from 'react'
import React, { useCallback } from 'react'

import {
  SliderDescription,
  SliderValue,
  SliderHours,
  SliderHead,
  BoxedOuter,
  SliderLabel,
} from './styled'

interface SliderBoxProps {
  name: string
  label: ReactNode
  description: ReactNode
  value: number
  onChange?: (value: number) => void
}

const SliderBox = ({
  name,
  label,
  value,
  description,
  onChange,
}: SliderBoxProps) => {
  const handleChange = useCallback(
    (event) => {
      onChange && onChange(+event.target.value || 0)
    },
    [onChange],
  )

  return (
    <BoxedOuter>
      <SliderHead>
        <SliderLabel>{label}</SliderLabel>
        <SliderHours>{`${value} hours`}</SliderHours>
      </SliderHead>
      <SliderValue>
        <input
          name={name}
          type={'range'}
          min={0}
          max={10}
          step={1}
          value={value}
          onChange={handleChange}
        />
      </SliderValue>
      <SliderDescription>{description}</SliderDescription>
    </BoxedOuter>
  )
}

export default SliderBox
