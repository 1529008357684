import React, { useState } from 'react'

import type {
  Maybe,
  ContentfulCustomModuleParamsJsonNodeAssistants,
} from '../../../../../graphql-types'
import Switcher from '../../../forms/Switcher'
import Container from '../../Container'

import {
  PerkOuter,
  PerkLabel,
  PerkText,
  SwitcherContainer,
  ItemsContainer,
  Emoji,
} from './styled'

type Perk = Pick<
  ContentfulCustomModuleParamsJsonNodeAssistants,
  'label' | 'text' | 'emoji'
>

type Props = {
  assistants?: Maybe<Maybe<Perk>[]>
  hq?: Maybe<Maybe<Perk>[]>
}

const Perk = (props: Perk) => (
  <PerkOuter>
    <Emoji>{props.emoji}</Emoji>
    <div>
      <PerkLabel>{props.label}</PerkLabel>
      <PerkText>{` ${props.text}`}</PerkText>
    </div>
  </PerkOuter>
)

const WhatWeOffer = ({ assistants, hq }: Props) => {
  const [showAssistants, setShowAssistants] = useState(true)
  const items = (showAssistants ? assistants : hq)?.filter(Boolean) || []

  return (
    <Container>
      <SwitcherContainer>
        <Switcher
          leftLabel={'Assistants'}
          rightLabel={'HQ Team'}
          isLeft={showAssistants}
          onSwitch={setShowAssistants}
          $width={330}
        />
      </SwitcherContainer>

      {!!items.length && (
        <ItemsContainer>
          {items.map((perk, index) => (
            <Perk key={index} {...perk} />
          ))}
        </ItemsContainer>
      )}
    </Container>
  )
}

export default WhatWeOffer
