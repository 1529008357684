import styled, { css } from 'styled-components'

import { Color } from '../../../../components/theme'
import { desktop, mobile, tablet } from '../../../mixins'
import { bodyRegularStyle, calloutSemiboldStyle } from '../../../typography'
import _Container from '../../Container'

export const Container = styled(_Container)`
  align-items: center;
`

const paragraphStyle = css`
  ${bodyRegularStyle({ noResponsive: true })};
  text-align: center;
  max-width: 552px;
`
export const TopParagraph = styled.p`
  ${paragraphStyle}
  margin-bottom: 52px;
  ${tablet(css`
    margin-top: 0;
    margin-bottom: 40px;
  `)}
`
export const BottomParagraph = styled.p`
  ${paragraphStyle}
  margin-top: 72px;
  margin-bottom: 72px;
  ${tablet(css`
    margin-bottom: 40px;
    margin-top: 40px;
  `)}
`

export const StatsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 24px;
  padding: 0 36px;

  ${tablet(css`
    row-gap: 32px;
    align-items: center;
    flex-direction: column;
    padding: 0;
  `)}
`

export const StatCard = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${Color.GRAY_3};
  border-radius: 32px;
  flex-basis: 30%;
  padding: 32px 24px;
  flex-grow: 1;

  ${tablet(css`
    width: 100%;
    max-width: 360px;
  `)}
`

export const InitiativesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;

  ${desktop(css`
    padding: 0 34px;
  `)}

  ${tablet(css`
    max-width: 552px;
  `)}

  ${mobile(css`
    flex-direction: column;
  `)}
`

export const InitiativeCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Color.GRAY_3};
  border-radius: 32px;
  flex-basis: 20%;
  padding: 32px 24px;
  min-height: 184px;
  text-align: center;
  flex-grow: 1;

  & p {
    ${calloutSemiboldStyle({ noResponsive: true })};
  }

  & > span {
    font-size: 32px;
    margin-bottom: 8px;
  }

  ${tablet(css`
    flex-basis: 45%;
  `)}

  ${mobile(css`
    padding: 24px;
  `)}
`

export const Img = styled.img`
  width: 100%;
  max-width: 272px;
`

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${Color.GRAY_3};
  margin-top: 50px;
  margin-bottom: 50px;

  ${tablet(css`
    margin-top: 40px;
    margin-bottom: 40px;
  `)}
`
