import styled, { css } from 'styled-components'

import { mobile, tablet } from '../../../mixins'
import { bodyRegularStyle, bodySemiboldStyle } from '../../../typography'

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 64px 72px;
  padding: 32px 60px 0;

  ${tablet(css`
    flex-direction: column;
    align-items: flex-start;
    padding: 0 24px;
  `)}

  ${mobile(css`
    padding: 0 0 0 4px;
    row-gap: 24px;
  `)}
`

export const SwitcherContainer = styled.div`
  margin-bottom: 52px;
  width: 100%;

  ${mobile(css`
    margin-bottom: 40px;
  `)}
`

export const PerkOuter = styled.div`
  display: flex;
  gap: 20px;
  flex: 1 0 45%;
`

export const Emoji = styled.span`
  font-size: 24px;
`

export const PerkLabel = styled.span`
  white-space: nowrap;
  ${bodySemiboldStyle};
`

export const PerkText = styled.span`
  ${bodyRegularStyle};
`
