import type { ComponentProps } from 'react'
import React from 'react'
import styled from 'styled-components'

import type { LargeImageFragment } from '../../../graphql-types'

import Img from './Img'

const Image = styled(Img)`
  width: 100%;
`

type Props = LargeImageFragment & ComponentProps<typeof Image>

const LargeImage = ({ __typename, image, ...props }: Props) => (
  <Image asset={image} {...props} />
)

export default LargeImage
