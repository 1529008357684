import React, { useEffect } from 'react'
import styled from 'styled-components'

import { BodyRegular, BodySemibold } from '../../../typography'

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(153, 153, 153, 0.5);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalInner = styled.div`
  overflow: hidden;
  background: white;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  max-width: 512px;
  padding: 48px 32px 0 32px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 64px);
`

const ModalBody = styled(BodyRegular)`
  margin-bottom: 32px;
  flex: 1 1;
  overflow: auto;
`

const ModalTitle = styled(BodySemibold)`
  margin-bottom: 6px;
`

const ModalButtons = styled.div`
  margin-bottom: 48px;
  text-align: right;
`

const ModalButton = styled.button``

interface ModalProps {
  onRequestClose?: () => void
}

const Modal = ({ onRequestClose }: ModalProps) => {
  useEffect(() => {
    const onKeydown = () => {
      onRequestClose && onRequestClose()
    }

    window.addEventListener('keydown', onKeydown, false)

    return () => {
      window.removeEventListener('keydown', onKeydown, false)
    }
  }, [onRequestClose])

  return (
    <ModalBackdrop onClick={onRequestClose}>
      <ModalInner>
        <ModalTitle>{'How did we calculate this?'}</ModalTitle>
        <ModalBody>
          {
            'We took the weekly # of hours you input, and applied a smart multiplier per task category to arrive at our weekly estimate for you.'
          }
          <br />
          {
            'For example: most executives who successfully delegate their inbox will still spend some time sending emails.'
          }
          <br />
          {
            'But if they currently spend 4 hours/week on inbox, most are able to shave off 85% of the time, by delegating it to a competent assistant.'
          }
          <br />
          {
            "We've applied a unique multiplier to each task category, based on what we know about textbook delegation principles, and what our best practice community of executives are already doing. 😉"
          }
        </ModalBody>
        <ModalButtons>
          <ModalButton onClick={onRequestClose}>{'Close'}</ModalButton>
        </ModalButtons>
      </ModalInner>
    </ModalBackdrop>
  )
}

export default Modal
