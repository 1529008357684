import styled, { css } from 'styled-components'

import Link from '../../../../components/modules/Link'
import { mobile } from '../../../mixins'
import { Color } from '../../../theme'
import {
  BodyRegular,
  BodySemibold,
  bodySemiboldStyle,
} from '../../../typography'
import Container from '../../Container'

export const Text = styled(BodyRegular)`
  text-align: center;
`

export const Outer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 720px;

  & > a + a {
    margin-top: 24px;
  }
`

export const Job = styled.div`
  width: 100%;
  background: white;
  box-shadow: 0px 12px 32px rgba(98, 104, 111, 0.2);
  border-radius: 20px;
  padding: 24px;
  display: flex;
  justify-content: space-between;

  ${mobile(css`
    padding: 20px;
    flex-direction: column;
  `)}
`

export const SwitcherContainer = styled.div`
  margin-bottom: 52px;
  width: 100%;

  ${mobile(css`
    margin-bottom: 32px;
  `)}
`

export const Title = styled(BodySemibold)``
export const Location = styled(BodyRegular)`
  color: ${Color.GRAY_6};
`

export const InfoBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const InfoBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 32px 38px;
  margin-top: 52px;
  border-radius: 20px;
  border: solid 1px ${Color.GRAY_3};
  width: 100%;
  max-width: 720px;
  gap: 24px;

  ${mobile(css`
    margin-top: 32px;
    padding: 20px 16px;
    gap: 8px;
  `)}
`

export const InfoBoxIcon = styled.span`
  font-size: 30px;

  ${mobile(css`
    font-size: 20px;
  `)}
`

export const ContainerCenter = styled(Container)`
  align-items: center;
`

export const LearnMore = styled(Link)`
  margin-top: 12px;

  & h3 {
    ${bodySemiboldStyle({ noColor: true })}
  }
`
